import { GeneralGingrProfileDetailsState } from './gingrProfileDetails.reducer';
import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';
import { GingrImagesToBeApproved } from './GingrPhotos/userPhotos.reducer';
import { APPROVAL_STATUS } from './GingrDetails';
import {UserVerification} from "./GingrVerification/userVerification.reducer";
import {AdminUserService} from "../../../../service/AdminUserService";
import {GingrClient3} from "../../../../clients/GingrClient3";
import {UserMedia} from "./GingrPhotos/user-media";

const ProductServicePaths = {
  GINGR_PROFILE_DETAILS: '/admin/users/gingr/',
  GINGR_IMAGES: (id: number | string, type: APPROVAL_STATUS) =>
    `/admin/medias/users/${id}/image?approved=${type}`,
  GINGR_VIDEOS: (id: string, type?: APPROVAL_STATUS) =>
    `/admin/medias/users/${id}/video?approved=${type}`,
  APPROVE_IMAGES: (id: string) => `/admin/medias/users/${id}/approve`,
  APPROVE_VIDEOS: (id: string) => `/admin/medias/users/${id}/approve`,
  APPROVAL_PROCESS: (id: string) => `/admin/users/${id}/approvable`,
  REJECT_PHOTOS: (id: string) => `/admin/medias/users/${id}/reject`,
  GINGR_VERIFICATION : (id: string) =>
      `/admin/users/${id}/idverification`,
  APPROVE_VERIFICATION: (id: string) => `/admin/users/${id}/idverification/approve`,
  REJECT_VERIFICATION:  (id: string) => `/admin/users/${id}/idverification/reject`,
};

export class GingrProfileDetailsService {
  static async getGingrProfileDetails(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<GeneralGingrProfileDetailsState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.GINGR_PROFILE_DETAILS + id,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async getGingrImages(
    credentials: {
      token: string;
    },
    userId: number | string,
    approvedStatus: APPROVAL_STATUS,
  ): Promise<UserMedia[]> {
    return await GingrClient3.post<UserMedia[]>(
        `admin/media`,
        {userId, approvedStatus,images: true },
        BaseService.getBaseHeaders(credentials.token)
    );
  }
  static async getGingrVideos(
    credentials: {
      token: string;
    },
    userId: number | string,
    approvedStatus: APPROVAL_STATUS
  ): Promise<UserMedia[]> {
    return await GingrClient3.post<UserMedia[]>(
        `admin/media`,
        {userId, approvedStatus,images: false },
        BaseService.getBaseHeaders(credentials.token)
    );
  }

  static async getGingrVerification(
      credentials: {
        token: string;
      },
      id: number | string,
  ): Promise<any> {
    if (id) {
      let  user  = await AdminUserService.getVerificationStatus(+id, credentials.token)
      return [user]
    }
    return null;
  }

  static async approveImages(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<Array<GingrImagesToBeApproved>> {
    const { result } = await GingrClient.post(
      ProductServicePaths.APPROVE_IMAGES(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async approveVideos(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<Array<GingrImagesToBeApproved>> {
    const { result } = await GingrClient.post(
      ProductServicePaths.APPROVE_IMAGES(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async rejectImages(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<boolean> {
    const { result } = await GingrClient.post(
      ProductServicePaths.REJECT_PHOTOS(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async rejectVideos(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<boolean> {
    const { result } = await GingrClient.post(
      ProductServicePaths.REJECT_PHOTOS(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
}
