import React from 'react';
import { Grid } from '@material-ui/core';
import './UserPersonalDetails.scss';
import { GeneralAgencyProfileDetailsState } from '../agencyProfileDetails.reducer';

interface AgencyPersonalDetailsProps {
  data?: GeneralAgencyProfileDetailsState | any;
}
export const UserPersonalDetails = ({ data }: AgencyPersonalDetailsProps) => {
  return (
    <div className="userPersonalDetailsContainer">
      <div className="description">
        <p>{data.profileDetails.description}</p>
        <span>Description</span>
      </div>
      <div className="personalDetailsContent">
        <Grid container spacing={4}>
          {data.profileDetails.companyName === 'company' && (
            <Grid item xs={4}>
              <p>{data.profileDetails.firstName}</p>
              <span>Company Name</span>
            </Grid>
          )}

          {data.profileDetails.companyName === 'agent' && (
            <Grid item xs={4}>
              <p>{data.firstName}</p>
              <span>Name</span>
            </Grid>
          )}
          {data.profileDetails.companyName === 'agent' && (
            <Grid item xs={4}>
              <p>{data.profileDetails.lastName}</p>
              <span>Surname</span>
            </Grid>
          )}
          <Grid item xs={4}>
            <p>{data.profileDetails.location?.formatted_address}</p>
            <span>Location</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.profileDetails.contact}</p>
            <span>Contact Person</span>
          </Grid>

          <Grid item xs={4}>
            <p>{data.profileDetails.website}</p>
            <span>Website</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.profileDetails.booking_notes}</p>
            <span>Booking Notes</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
