export interface TransactionModel {
  id: number;
  amount: number;
  bookingId?: number;
  deducted: boolean;
  dateCreated: string;
  userId: number;
  user: TransactionUserModel;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  paymentMethod?: PaymentMethod;
}

export interface TransactionUserModel {
  avatar?: string;
  displayName?: string;
  id: number;
  role: number;
}

export enum TransactionType {
  Welcome = 'Welcome',
  Support = 'Support',
  TopUp = 'TopUp',
  ServiceFee = 'ServiceFee',
  ServiceFeeRefund = 'ServiceFeeRefund',
  CompensationRefund = 'CompensationRefund',
  CancellationFee = 'CancellationFee',
  Referral = 'Referral',
}

export enum PaymentMethod {
  BankTransfer = 'BankTransfer',
  PaymentSlip = 'PaymentSlip',
  Cash = 'Cash',
  Card = 'Card',
}

export enum TransactionStatus {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Rejected = 'Rejected',
}
