import React from 'react';
import logo from '../../../../assets/img/logo/logo1.svg';
import { ErrorMessage, TextInput } from '../TextInput/TextInput';
import { MainButton } from '../MainButton';
import '../Login.scss';
import { goToLoginSequence } from '../login.action';
import { useDispatch } from 'react-redux';
import { LoginHeader } from '../LoginHeader/LoginHeader';
import {CredentialsService} from "../../../../service/CredentialsService";

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");
  const [error, setError] = React.useState<ErrorMessage>({
    error: false,
    message: '',
  });

  const validation = () => {
    const checkIfEmailIsValid = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(
      email
    );
    if (!checkIfEmailIsValid) {
      setError({
        error: true,
        message:
          'Invalid email address, please type already existing email address',
      });
    } else {
      setError({
        error: false,
        message: '',
      });
    }
  };
  const returnToUsernameAndPasswordScreen = () => {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
  };

  const sendEmail = async () => {
    if (!error.error && email) {
      setLoading(true);
      const response = await CredentialsService.forgotPassword({
        email
      });
      setResponseMessage(response.message);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <img src={logo} alt="logo" />
      <div className="login-container">
        <LoginHeader
          title="Forgot password"
          goBack={returnToUsernameAndPasswordScreen}
        />
        <div className="input-container">
          <label className="input-label" htmlFor="email-input">
            E-mail
          </label>
          <TextInput
            value={email}
            id="email-input"
            label="Type your email"
            error={error}
            handleOnChange={(value) => {
              setEmail(value);
              validation();
            }}
          />
        </div>
        {responseMessage ? (
            <div className="response-message">
              <div className="input-label">{responseMessage}</div>
            </div>
        ) : (
            <div className="button-container">
              <MainButton loading={loading} text="Send" fullWidth onClick={sendEmail}/>
            </div>
        )}
      </div>
    </div>
  );
};
