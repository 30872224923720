export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export class GingrClient {
  private static config = window.app_config || {};
  private static readonly BASE_URL = this.config.api_url;

  static async get<T>(path: string, headers?: Record<string, any>): Promise<T> {
    const response = await fetch(`${this.BASE_URL}${path}`, {
      headers,
      mode: 'cors',
    });
    let body;

    try {
      body = await response.json();
    } catch (ex) {}

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return body;
  }

  static async requestWithBody<T>(
    method: HttpMethod,
    path: string,
    body?: Record<string, any>,
    headers: Record<string, any> = {}
  ): Promise<T> {
    const response = await fetch(`${this.BASE_URL}${path}`, {
      headers: {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      console.log('Error response status text: ', response.statusText);
    }

    return await response.json();
  }

  static async post<T>(
    path: string,
    body: Record<string, any>,
    headers: Record<string, any> = {}
  ): Promise<T> {
    return await this.requestWithBody<T>(HttpMethod.POST, path, body, headers);
  }

  static async put<T>(
    path: string,
    body: Record<string, any>,
    headers: Record<string, any> = {}
  ): Promise<T> {
    return await this.requestWithBody<T>(HttpMethod.PUT, path, body, headers);
  }

  static async delete<T>(
    path: string,
    body?: Record<string, any>,
    headers: Record<string, any> = {}
  ): Promise<T> {
    return await GingrClient.requestWithBody<T>(
      HttpMethod.DELETE,
      path,
      body,
      headers
    );
  }

  static async login<T>(path: string, body: any): Promise<T> {
    return fetch(`${this.BASE_URL}${path}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: HttpMethod.POST,
      body: JSON.stringify(body),
    })
      .then((result) => {
        return result.json();
      })
      .catch((err) => {
        throw new Error(err);
      });
  }
}
