import { GeneralUsersState } from './clients.reducer';
import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';

const ProductServicePaths = {
  USERS: '/admin/users?role=client',
  DELETE_USER: (id: string) => `/admin/users/${id}/delete`,
};

export class ClientService {
  static async getUsers(
    credentials: {
      token: string;
    },
    filters?: any
  ): Promise<GeneralUsersState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.USERS + filters,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result.users;
  }

  static async deleteUser(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<any> {
    const { result } = await GingrClient.post(
      ProductServicePaths.DELETE_USER(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
}
