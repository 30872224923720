import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { DashboardService } from '../Dashboard/DashboardService';

export type PendingApprovalCountActionType =
  | 'FETCH_PENDING_APPROVAL_USERS'
  | 'REMOVE_PENDING_APPROVAL_USERS';

export interface SetPendingApprovalCountAction
  extends Action<PendingApprovalCountActionType> {
  clientpendingApproval: number;
  gingrpendingApproval: number;
  establishmentpendingApproval: number;
  agencypendingApproval: number;
}

export const addPendingApprovalCount = (
  clientpendingApproval: number,
  gingrpendingApproval: number,
  establishmentpendingApproval: number,
  agencypendingApproval: number
): SetPendingApprovalCountAction => ({
  type: 'FETCH_PENDING_APPROVAL_USERS',
  clientpendingApproval,
  gingrpendingApproval,
  establishmentpendingApproval,
  agencypendingApproval,
});

export const removePendingApprovalCount = (
  clientpendingApproval: number,
  gingrpendingApproval: number,
  establishmentpendingApproval: number,
  agencypendingApproval: number
): SetPendingApprovalCountAction => ({
  type: 'REMOVE_PENDING_APPROVAL_USERS',
  clientpendingApproval,
  gingrpendingApproval,
  establishmentpendingApproval,
  agencypendingApproval,
});

export const fetchPendingApprovalUsersCount =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('FETCH_PENDING_APPROVAL_USERS'));
    const credentials = getCredentials(getState());

    try {
      const {
        clientpendingApproval,
        gingrpendingApproval,
        establishmentpendingApproval,
        agencypendingApproval,
      } = await DashboardService.getUsersPendingForApprovalCount({
        token: credentials.token,
      });

      dispatch(
        addPendingApprovalCount(
          clientpendingApproval,
          gingrpendingApproval,
          establishmentpendingApproval,
          agencypendingApproval
        )
      );
    } catch (error) {
      console.log('Error during add pending approval count action: ', error);
    }
    dispatch(loadComplete('FETCH_PENDING_APPROVAL_USERS'));
  };
