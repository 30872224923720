import { Credentials } from './service.types';
import { GingrClient } from '../clients/GingrClient';
import { BaseService } from './BaseService';
import { Auth } from '@aws-amplify/auth';

export class CredentialsService {
  static async login(credentials: Credentials) {
    try {
      return await Auth.signIn(credentials.email, credentials.password).then(
        async (user) => {
          return user;
        }
      );
    } catch (e) {
      console.log('error signing in', e);
      return null;
    }
  }

  static async logout() {
    try {
      await Auth.signOut().then((response) => {
        console.log(response);
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  static async saveUserSessionInfo() {
    return await Auth.currentUserInfo().then(async (currentUser) => {
      if (currentUser?.username) {
        return await Auth.currentSession().then((session) => {
          return session?.getAccessToken()?.getJwtToken();
        });
      } else {
        return null;
      }
    });
  }

  static async completeNewPassword(newPassword, cognitoUser) {
    return await Auth.completeNewPassword(cognitoUser, newPassword)
      .then((result) => {
        return result;
      })
      .catch(() => {
        return null;
      });
  }

  static async changePassword(
    current_password: string,
    new_password: string,
    cognitoUser
  ) {
    let res;
    try {
      res = await Auth.changePassword(
        cognitoUser,
        current_password,
        new_password
      ).then((response) => response);
    } catch (e) {
      console.log('Error while trying to change password: ', e);
    }

    return res;
  }

  static async isValidPassword(
    current_password: string,
    token: string
  ): Promise<boolean> {
    let res;
    try {
      res = await GingrClient.post(
        `/validatePassword?password=${current_password}`,
        {},
        BaseService.getBaseHeaders(token)
      );
      return res.status === 200 && res.result.message === 'Password is correct';
    } catch (e) {
      console.log('Error while trying to validate password: ', e);
      return false;
    }
  }

  static async forgotPassword(email) {
    let res;
    try {
      res = await GingrClient.post('/admin/account/forgot_password', email);
    } catch (e) {
      return {
        message: 'Something went wrong. Please try again later',
      };
    }
    return res?.result;
  }

  static async resetPassword(token, password) {
    return await GingrClient.post('/admin/account/resetPassword', {
      token,
      password,
    });
  }

  static async validateToken(token) {
    return await GingrClient.post(
      '/admin/account/validateForgetToken',
      token
    ).catch((e) => console.log(e));
  }
}
