import { GeneralGingrState } from './gingr.reducer';
import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';
import {GingrClient3} from "../../../../clients/GingrClient3";
import {PaginatedResultModel} from "../../../../shared/paginated-result.model";
import {TransactionModel} from "../../Transactions/transaction.model";
import {UserModel} from "../user.model";
import {GeneralGingrProfileDetailsState} from "../GingrDetails/gingrProfileDetails.reducer";
import {GingrProfileDetails} from "../GingrDetails/gingrProfileDetails";

const ProductServicePaths = {
  GINGR: '/admin/users?role=gingr',
  DELETE_USER: (id: string) => `/admin/users/${id}/delete`,
};

export class GingrService {
  static async getUsers(
    token: string,
    filters?: any
  ): Promise<PaginatedResultModel<UserModel>> {
    try {
      return await GingrClient3.post<PaginatedResultModel<UserModel>>(
          `admin/user/list`,
          filters,
          BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return { resultCount: 0, results: [] };
    }
  }

  static async getUser(
      token: string, userId: string
  ): Promise<GingrProfileDetails | null> {
    try {
      return await GingrClient3.get<GingrProfileDetails>(
          `admin/user/${userId}`,
          BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }

  static async deleteUser(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<any> {
    const { result } = await GingrClient.post(
      ProductServicePaths.DELETE_USER(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result.users;
  }
}
