import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ActivityLog } from '../../ActivityLog/ActivityLog';
import { BookingAndRefunds } from '../../BookingAndRefunds/BookingAndRefunds';
import { Dashboard } from '../../Dashboard/Dashboard';
import { Payments } from '../../Payments/Payments';
import { Revenue } from '../../Revenue/Revenue';
import { Settings } from '../../Settings/Settings';
import { Subscriptions } from '../../Subscriptions/Subscriptions';
import './mainLayout.scss';
import { Clients } from '../../Users/Clients/Clients';
import { Gingrs } from '../../Users/Gingrs/Gingrs';
import { Agencies } from '../../Users/Agencies/Agencies';
import { Data } from '../../Data/Data';
import { Sidebar } from '../../Sidebar/Sidebar';
import { Reports } from '../../Reports/Reports';
import ProtectedRoute from '../../ProtectedRoute/ProtectedRoute';
import { Establishments } from '../../Users/Establishments/Establishments';
import { EstablishmentsDetails } from '../../Users/EstablishmentsDetails/EstablishemntDetails';
import { UserType } from '../../Subscriptions/SubscriptionTable';
import { GingrDetails } from '../../Users/GingrDetails/GingrDetails';
import { ClientDetails } from '../../Users/ClientsDetails/ClientDetails';
import { AgenciesDetails } from '../../Users/AgenciesDetails/AgencyDetails';
import { CountriesList } from '../../Content/Country/CountriesList';
import { CountryDetails } from '../../Content/Country/CountryDetails';
import { CityDetails } from '../../Content/City/CityDetails';
import { CityContentDetails } from '../../Content/City/CityContentDetails';
import { Transactions } from '../../Transactions/Transactions';
import { CreateTransaction } from '../../Transactions/CreateTransaction/CreateTransaction';

const routePrefix = (userType: UserType) => `users/${userType}/`;

export enum UserDetailsRoutes {
  PERSONAL_DETAILS = 'personal-details',
  PHOTOS = 'photos',
  VIDEOS = 'videos',
  REVIEWS = 'reviews',
  BOOKINGS = 'bookings',
  SUBSCRIPTIONS = 'subscriptions',
  REFERRALS = 'referrals',
  ACTIVITIES = 'activities',
  VERIFICATION = 'verification',
  SECURITY = 'security',
  WALLET_MANAGEMENT = 'wallet-management',
  TIER_ALLOCATION = 'tier-allocation',
  COMPLIANCE_STATUS = 'compliance-status',
  EXPORT_FUNCTION = 'export-function',
  PROFILE_DETAILS = 'profile-details',
  PHYSICAL_DETAILS = 'physical-details',
  SERVICES = 'services',
  AVAILABILITY = 'availability',
  PRICING = 'pricing',
  FACILITIES = 'facilities',
  OPENING_HOURS = 'opening-hours',
  MANAGE_GINGRS = 'manage-gingrs',
  DEFAULT_GINGR_SETTINGS = 'default-gingr-settings',
}

export const MainLayout: React.FC = () => {
  return (
    <BrowserRouter>
      <div className="page page-main">
        <div className="page-sidebar">
          <Sidebar />
        </div>
        <div className="page-content">
          <Switch>
            <ProtectedRoute exact path={'/dashboard'} component={Dashboard} />

            <ProtectedRoute exact path={'/users/clients'} component={Clients} />
            <ProtectedRoute
              path={'/users/clients/:id/'}
              component={ClientDetails}
            />

            <ProtectedRoute exact path={'/users/gingrs'} component={Gingrs} />

            <ProtectedRoute
              path={'/users/gingrs/:id/'}
              component={GingrDetails}
            />

            <ProtectedRoute
              exact
              path={'/users/establishments'}
              component={Establishments}
            />

            <ProtectedRoute
              path={'/users/establishments/:id'}
              component={EstablishmentsDetails}
            />
            <ProtectedRoute
              exact
              path={'/users/agencies'}
              component={Agencies}
            />
            <ProtectedRoute
              path={'/users/agencies/:id'}
              component={AgenciesDetails}
            />
            <ProtectedRoute
              exact
              path={'/activity-log'}
              component={ActivityLog}
            />
            <ProtectedRoute exact path={'/revenue'} component={Revenue} />
            <ProtectedRoute
              exact
              path={'/subscriptions'}
              component={Subscriptions}
            />
            <ProtectedRoute exact path={'/payments'} component={Payments} />
            <ProtectedRoute
              exact
              path={'/transactions'}
              component={Transactions}
            />
            <ProtectedRoute
              exact
              path={'/transactions/create'}
              component={CreateTransaction}
            />
            <ProtectedRoute
              exact
              path={'/booking-refunds'}
              component={BookingAndRefunds}
            />
            <ProtectedRoute exact path={'/reports'} component={Reports} />
            <ProtectedRoute exact path={'/data'} component={Data} />
            <ProtectedRoute exact path={'/settings'} component={Settings} />
            <ProtectedRoute
              exact
              path={'/content/countries'}
              component={CountriesList}
            />
            <ProtectedRoute
              exact
              path={'/content/countries/:id/'}
              component={CountryDetails}
            />
            <ProtectedRoute
              exact
              path={'/content/cities/:id'}
              component={CityDetails}
            />
            <ProtectedRoute
              exact
              path={'/content/cities/:id/:contentType'}
              component={CityContentDetails}
            />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};
