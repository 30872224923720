import { GeneralUserProfileDetailsState } from './userProfileDetails.reducer';
import { GingrClient } from '../../../../../clients/GingrClient';
import { BaseService } from '../../../../../service/BaseService';

const ProductServicePaths = {
  USERS_PROFILE_DETAILS: '/admin/users/client/',
};

export class UserProfileDetailsService {
  static async getUserProfileDetails(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<GeneralUserProfileDetailsState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.USERS_PROFILE_DETAILS + id,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
}
