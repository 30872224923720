import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { GingrService } from './GingrService';

import { GeneralGingrState } from './gingr.reducer';
import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getCredentials } from '../../../../shared/selectors/getCredentials';

export type GingrActionType = 'GINGR';

export type GingrAction = 'ADD_GINGR' | 'CLEAR_GINGR';

export interface AddGingrAction extends Action<GingrAction> {
  type: 'ADD_GINGR';
  users: GeneralGingrState[];
}

export interface ClearGingrAction extends Action<GingrAction> {
  type: 'CLEAR_GINGR';
}

export const addUsers = (users: GeneralGingrState[]): AddGingrAction => ({
  type: 'ADD_GINGR',
  users,
});

export const clearAgentInformation = (): ClearGingrAction => ({
  type: 'CLEAR_GINGR',
});

export const fetchGingrAction =
  (filters?: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('GINGR'));
    let response: any;
    const credentials = getCredentials(getState());
    let finalFilter = '';
    if (filters) {
      const newData = {
        country: filters.country.id,
        gender: filters.gender.id,
        sexuality: filters.sexuality.id,
        proUser: filters.proUser.id,
        onlyOnlineUsers: filters.onlyOnlineUsers,
        birthYear: filters.birthYear.name,
        bookingsNumber: filters.bookingsNumber.name,
        profileStatus: filters.profileStatus.id,
        verificationStatus: filters.verificationStatus.id,
      };

      if (newData.country) {
        finalFilter = finalFilter + '&country=' + newData.country;
      }
      if (newData.gender) {
        finalFilter = finalFilter + '&gender=' + newData.gender;
      }
      if (newData.sexuality) {
        finalFilter = finalFilter + '&sexuality=' + newData.sexuality;
      }
      if (newData.proUser) {
        finalFilter = finalFilter + '&proUser=' + newData.proUser;
      }
      if (newData.onlyOnlineUsers) {
        finalFilter = finalFilter + '&online=' + newData.onlyOnlineUsers;
      }
      if (newData.birthYear) {
        finalFilter = finalFilter + '&birthYear=' + newData.birthYear;
      }
      if (newData.bookingsNumber) {
        finalFilter =
          finalFilter + '&bookingsFrom=' + newData.bookingsNumber.split('-')[0];
        finalFilter =
          finalFilter + '&bookingsTo=' + newData.bookingsNumber.split('-')[1];
      }
      if (newData.profileStatus) {
        finalFilter = finalFilter + '&profileStatus=' + newData.profileStatus;
      }
    }
    const filters1 = finalFilter;
    console.log(filters1);
    try {
      response = await GingrService.getUsers(credentials, filters1);
      dispatch(addUsers(response));
    } catch (error) {
      console.log('Error during add users action: ', error);
    }
    dispatch(loadComplete('GINGR_FETCH'));
  };
