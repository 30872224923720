import { GeneralEstablishmentState } from './establishment.reducer';
import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';

const ProductServicePaths = {
  ESTABLISHMENT: '/admin/users?role=establishment',
  DELETE_USER: (id: string) => `/admin/users/${id}/delete`,
};

export class EstablishmentService {
  static async getEstablishment(
    credentials: {
      token: string;
    },
    filters?: any
  ): Promise<GeneralEstablishmentState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.ESTABLISHMENT + filters,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result.users;
  }

  static async deleteUser(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<any> {
    const { result } = await GingrClient.post(
      ProductServicePaths.DELETE_USER(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result.users;
  }
}
