import { Action } from 'redux';
import produce from 'immer';
import { AddEstablishmentProfileDetailsAction } from './establishmentProfileDetails.action';

export interface GeneralEstablishmentProfileDetailsState {
  id: number;
  displayname: string;
  companyName: any;
  firstName: any;
  lastName: any;
  email: string;
  proUser: boolean;
  profileStatus: number;
  createdAt: Date | any;
  slug: string;
  description: string;
  website: any;
  contact: any;
  booking_notes: string;
  currency: string;
  entryFee: any;
  availability: Availability[];
  services: Service[];
  mobile: Mobile;
  landline: Landline;
  avatar: Avatar;
  location: Location;
}

export interface Availability {
  day: number;
  from: string;
  to: string;
  from2: any;
  to2: any;
  from3: any;
  to3: any;
  enabled: number;
  isDefaultGingr: number;
  fullDay: boolean;
}

export interface Service {
  id: number;
  description: string;
  name: string;
  service_name: string;
  price?: number;
  currency?: string;
  symbol?: string;
  excerpt: string;
  ambit: string;
  activity: any;
}

export interface Mobile {
  prefix: any;
  number: any;
}

export interface Landline {
  prefix: string;
  number: string;
}

export interface Avatar {
  id: number;
  type: string;
  media: string;
  thumb: string;
  thumbblur: string;
}

export interface Location {
  formatted_address: string;
  longitude: number;
  latitude: number;
  country: string;
  city: string;
  isArea: number;
  have_working_address: number;
}

export type EstablishmentProfileDetailsAction =
  | 'ADD_ESTABLISHMENT_PROFILE_DETAILS'
  | 'CLEAR_ESTABLISHMENT_PROFILE_DETAILS';

export type EstablishmentProfileDetailsState =
  | 'INCOMPLETE'
  | GeneralEstablishmentProfileDetailsState;

export const establishmentProfileDetailsReducer = (
  state: EstablishmentProfileDetailsState = 'INCOMPLETE',
  action: Action<EstablishmentProfileDetailsAction>
): EstablishmentProfileDetailsState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_ESTABLISHMENT_PROFILE_DETAILS':
        const addEstablishmentProfileDetailsAction =
          action as AddEstablishmentProfileDetailsAction;
        const {
          id,
          displayname,
          companyName,
          firstName,
          lastName,
          email,
          profileStatus,
          proUser,
          createdAt,
          slug,
          description,
          website,
          contact,
          booking_notes,
          availability,
          services,
          currency,
          entryFee,
          mobile,
          landline,
          avatar,
          location,
        } = addEstablishmentProfileDetailsAction;
        return {
          id,
          displayname,
          companyName,
          firstName,
          lastName,
          email,
          profileStatus,
          proUser,
          createdAt,
          slug,
          description,
          website,
          contact,
          booking_notes,
          availability,
          services,
          currency,
          entryFee,
          mobile,
          landline,
          avatar,
          location,
        };
      case 'CLEAR_ESTABLISHMENT_PROFILE_DETAILS':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
