import React from 'react';
import './UserAvailability.scss';

export const UserAvailability = () => {
  return (
    <div className="userAvailability">
      <div className="left">
        <div className="tableContainer">
          <h2>Availability</h2>
          <table>
            <tr>
              <th>Days</th>
              <th>Hours</th>
            </tr>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>00:00-24:00</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>00:00-24:00</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>00:00-24:00</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>00:00-24:00</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>00:00-24:00</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>00:00-24:00</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>00:00-24:00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
