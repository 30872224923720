import React from 'react';
import './UserOpeningHours.scss';
import { Availability } from '../../GingrDetails/gingrProfileDetails.reducer';

type UserAvailabilityProps = { data: Availability[] };

export const UserOpeningHours = ({ data }: UserAvailabilityProps) => {
  return (
    <div className="userOpeningHours">
      <div className="left">
        <div className="tableContainer">
          <h2>Opening Hours</h2>
          <table>
            <tr>
              <th>Days</th>
              <th>Hours</th>
            </tr>
            {data && data.length > 0 && (
              <tbody>
                <tr>
                  <td>Monday</td>
                  <td>
                    {data[0].from &&
                      data[0].to &&
                      data[0].from + '-' + data[0].to}{' '}
                    {data[0].from2 &&
                      data[0].to2 &&
                      ', ' + data[0].from2 + '-' + data[0].to2}{' '}
                    {data[0].from3 &&
                      data[0].to3 &&
                      ', ' + data[0].from3 + '-' + data[0].to3}{' '}
                    {data[0].from === null &&
                      data[0].to === null &&
                      data[0].from2 === null &&
                      data[0].to2 === null &&
                      data[0].from3 === null &&
                      data[0].to3 === null &&
                      'Closed'}
                  </td>
                </tr>
                <tr>
                  <td>Tuesday</td>
                  <td>
                    {data[1].from &&
                      data[1].to &&
                      data[1].from + '-' + data[1].to}
                    {data[1].from2 &&
                      data[1].to2 &&
                      ', ' + data[1].from2 + '-' + data[1].to2}
                    {data[0].from3 &&
                      data[1].to3 &&
                      ', ' + data[1].from3 + '-' + data[1].to3}{' '}
                    {data[1].from === null &&
                      data[1].to === null &&
                      data[1].from2 === null &&
                      data[1].to2 === null &&
                      data[1].from3 === null &&
                      data[1].to3 === null &&
                      'Closed'}
                  </td>
                </tr>
                <tr>
                  <td>Wednesday</td>
                  <td>
                    {data[2].from &&
                      data[2].to &&
                      data[2].from + '-' + data[2].to}
                    {data[2].from2 &&
                      data[2].to2 &&
                      ', ' + data[2].from2 + '-' + data[2].to2}
                    {data[2].from3 &&
                      data[2].to3 &&
                      ', ' + data[2].from3 + '-' + data[2].to3}{' '}
                    {data[2].from === null &&
                      data[2].to === null &&
                      data[2].from2 === null &&
                      data[2].to2 === null &&
                      data[2].from3 === null &&
                      data[2].to3 === null &&
                      'Closed'}
                  </td>
                </tr>
                <tr>
                  <td>Thursday</td>
                  <td>
                    {data[3].from &&
                      data[3].to &&
                      data[3].from + '-' + data[3].to}
                    {data[3].from2 &&
                      data[3].to2 &&
                      ', ' + data[3].from2 + '-' + data[3].to2}
                    {data[3].from3 &&
                      data[3].to3 &&
                      ', ' + data[3].from3 + '-' + data[3].to3}{' '}
                    {data[3].from === null &&
                      data[3].to === null &&
                      data[3].from2 === null &&
                      data[3].to2 === null &&
                      data[3].from3 === null &&
                      data[3].to3 === null &&
                      'Closed'}
                  </td>
                </tr>
                <tr>
                  <td>Friday</td>
                  <td>
                    {data[4].from &&
                      data[4].to &&
                      data[4].from + '-' + data[4].to}
                    {data[4].from2 &&
                      data[4].to2 &&
                      ', ' + data[4].from2 + '-' + data[4].to2}
                    {data[4].from3 &&
                      data[4].to3 &&
                      ', ' + data[4].from3 + '-' + data[4].to3}{' '}
                    {data[4].from === null &&
                      data[4].to === null &&
                      data[4].from2 === null &&
                      data[4].to2 === null &&
                      data[4].from3 === null &&
                      data[4].to3 === null &&
                      'Closed'}
                  </td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td>
                    {data[5].from &&
                      data[5].to &&
                      data[5].from + '-' + data[5].to}
                    {data[5].from2 &&
                      data[5].to2 &&
                      ', ' + data[5].from2 + '-' + data[5].to2}
                    {data[5].from3 &&
                      data[5].to3 &&
                      ', ' + data[5].from3 + '-' + data[5].to3}{' '}
                    {data[5].from === null &&
                      data[5].to === null &&
                      data[5].from2 === null &&
                      data[5].to2 === null &&
                      data[5].from3 === null &&
                      data[5].to3 === null &&
                      'Closed'}
                  </td>
                </tr>
                <tr>
                  <td>Sunday</td>
                  <td>
                    {data[6].from &&
                      data[6].to &&
                      data[6].from + '-' + data[6].to}
                    {data[6].from2 &&
                      data[6].to2 &&
                      ', ' + data[6].from2 + '-' + data[6].to2}
                    {data[6].from3 &&
                      data[6].to3 &&
                      ', ' + data[6].from3 + '-' + data[6].to3}{' '}
                    {data[6].from === null &&
                      data[6].to === null &&
                      data[6].from2 === null &&
                      data[6].to2 === null &&
                      data[6].from3 === null &&
                      data[6].to3 === null &&
                      'Closed'}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
