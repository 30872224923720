import React from 'react';
import './UserServices.scss';
import { OneColumnTable } from '../../OneColumnTable/OneColumnTable';
import { Divider } from '@mui/material';
import { Service } from '../../../EstablishmentsDetails/establishmentProfileDetails.reducer';
import { useTranslation } from 'react-i18next';

type UserServicesProps = { data: Service[] };

export const UserServices = ({ data }: UserServicesProps) => {
  const [t] = useTranslation('translation');
  const includedServices: string[] = [];
  const extraServices: any = [];

  data.map((value) => {
    const tempValue = t(`SERVICES.${value.name}.NAME`);
    if (value.price) {
      extraServices.push({ name: tempValue, price: value.price });
    } else {
      const tempValue = t(`SERVICES.${value.name}.NAME`);
      includedServices.push(tempValue);
    }
  });

  return (
    <div className="userServices">
      <div className="left">
        <h2>Included Services</h2>
        <div className="secondTableContainer">
          <OneColumnTable head="Service" data={includedServices} />
        </div>
      </div>
      <Divider
        variant="middle"
        style={{ backgroundColor: '#3a1b37', borderWidth: 1, margin: 10 }}
      />
      <div className="left">
        <div className="tableContainer">
          <h2>Extra services</h2>
          {extraServices.length > 0 && (
            <table>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
              <tbody>
                {extraServices &&
                  extraServices.map((row) => {
                    return (
                      <tr>
                        <td>{row.name}</td>
                        <td>{row.price} $</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
