import { GeneralAgencyState } from './agency.reducer';
import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';

const ProductServicePaths = {
  AGENCY: '/admin/users?role=agency',
  DELETE_USER: (id: string) => `/admin/users/${id}/delete`,
};

export class AgencyService {
  static async getAgency(
    credentials: {
      token: string;
    },
    filters?: any
  ): Promise<GeneralAgencyState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.AGENCY + filters,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result.users;
  }

  static async deleteUser(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<any> {
    const { result } = await GingrClient.post(
      ProductServicePaths.DELETE_USER(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result.users;
  }
}
