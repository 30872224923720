import React from 'react';
import { Grid } from '@material-ui/core';
import './UserPersonalDetails.scss';
import { GeneralEstablishmentProfileDetailsState } from '../establishmentProfileDetails.reducer';
interface EstablishmentPersonalDetailsProps {
  data?: GeneralEstablishmentProfileDetailsState | any;
}
export const UserPersonalDetails = ({
  data,
}: EstablishmentPersonalDetailsProps) => {
  return (
    <div className="userPersonalDetailsContainer">
      <div className="description">
        <p>{data.description}</p>
        <span>Description</span>
      </div>
      <div className="personalDetailsContent">
        <Grid container spacing={4}>
          {data.companyName === 'company' && (
            <Grid item xs={4}>
              <p>{data.firstName}</p>
              <span>Company Name</span>
            </Grid>
          )}

          {data.companyName === 'agent' && (
            <Grid item xs={4}>
              <p>{data.firstName}</p>
              <span>Name</span>
            </Grid>
          )}
          {data.companyName === 'agent' && (
            <Grid item xs={4}>
              <p>{data.lastName}</p>
              <span>Surname</span>
            </Grid>
          )}

          <Grid item xs={4}>
            <p>{data.currency}</p>
            <span>Default Currency</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.entryFee ? data.entryFee : 'No Entry Fee'}</p>
            <span>Entry Fee</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.location.formatted_address}</p>
            <span>Location</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.contact}</p>
            <span>Contact Person</span>
          </Grid>

          <Grid item xs={4}>
            <p>{data.website}</p>
            <span>Website</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.booking_notes}</p>
            <span>Booking Notes</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
