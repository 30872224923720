import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AgencyService } from './AgencyService';

import { GeneralAgencyState } from './agency.reducer';
import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getCredentials } from '../../../../shared/selectors/getCredentials';

export type AgencyActionType = 'USERS';

export type AgencyAction = 'ADD_AGENCY' | 'CLEAR_AGENCY';

export interface AddAgencyAction extends Action<AgencyAction> {
  type: 'ADD_AGENCY';
  users: GeneralAgencyState[];
}

export interface ClearAgencyAction extends Action<AgencyAction> {
  type: 'CLEAR_AGENCY';
}

export const addAgency = (users: GeneralAgencyState[]): AddAgencyAction => ({
  type: 'ADD_AGENCY',
  users,
});

export const clearAgentInformation = (): ClearAgencyAction => ({
  type: 'CLEAR_AGENCY',
});

export const fetchAgencyAction =
  (filters?: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('USERS'));
    let response: any;
    const credentials = getCredentials(getState());
    let finalFilter = '';
    if (filters) {
      const newData = {
        country: filters.country.id,
        connectedGingrs: filters.connectedGingrs.id,
        proUser: filters.proUser.id,
        onlyOnlineUsers: filters.onlyOnlineUsers,
        type: filters.type.id,
        bookingsNumber: filters.bookingsNumber.name,
        profileStatus: filters.profileStatus.id,
        verificationStatus: filters.verificationStatus.id,
      };

      if (newData.country) {
        finalFilter = finalFilter + '&country=' + newData.country;
      }
      if (newData.connectedGingrs) {
        finalFilter =
          finalFilter + '&connectedGingrs=' + newData.connectedGingrs;
      }
      if (newData.proUser) {
        finalFilter = finalFilter + '&proUser=' + newData.proUser;
      }
      if (newData.onlyOnlineUsers) {
        finalFilter = finalFilter + '&online=' + newData.onlyOnlineUsers;
      }
      if (newData.type) {
        finalFilter = finalFilter + '&type=' + newData.type;
      }
      if (newData.bookingsNumber) {
        finalFilter =
          finalFilter + '&bookingsFrom=' + newData.bookingsNumber.split('-')[0];
        finalFilter =
          finalFilter + '&bookingsTo=' + newData.bookingsNumber.split('-')[1];
      }
      if (newData.profileStatus) {
        finalFilter = finalFilter + '&profileStatus=' + newData.profileStatus;
      }
    }
    const filters1 = finalFilter;
    console.log(filters1);
    try {
      response = await AgencyService.getAgency(credentials, filters1);
      dispatch(addAgency(response));
    } catch (error) {
      console.log('Error during add users action: ', error);
    }
    dispatch(loadComplete('AGENCY_FETCH'));
  };
