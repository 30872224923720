import React, { useState } from 'react';
import { Header } from '../../../Elements/Header/Header';
import {
  Avatar,
  Badge,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Grid,
} from '@material-ui/core';
import { ChartButton } from '../../Dashboard/ChartButton';
import OptionsIcon from '../../../../assets/img/icon/options.svg';
import EditIcon from '../../../../assets/img/icon/edit.svg';
import './EstablishmentDetails.scss';
import { UserPersonalDetails } from './UserPersonalDetails/UserPersonalDetails';
import { SimpleButton } from '../../Revenue/SimpleButton/SimpleButton';
import {
  Route,
  RouteComponentProps,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { GeneralEstablishmentProfileDetailsState } from './establishmentProfileDetails.reducer';
import { fetchEstablishmentProfileDetailsAction } from './establishmentProfileDetails.action';
import { UserPricing } from '../GingrDetails/GingrProfileDetails/UserPricing/UserPricing';
import { UserServices } from '../GingrDetails/GingrProfileDetails/UserServices/UserServices';
import { UserAvailability } from './DefaultGingrSettings/UserAvailability/UserAvailability';
import { UserDetailsRoutes } from '../../Layouts/MainLayout/MainLayout';
import { GingrProfileDetailsService } from '../GingrDetails/GingrProfileDetailsService';
import { LinkItem } from '../LinkItem/LinkItem';
import UserPhotos from '../GingrDetails/GingrPhotos/UserPhotos';
import { AdminUserService } from '../../../../service/AdminUserService';
import { UserFacilities } from './UserFacilities/UserFacilities';
import { UserOpeningHours } from '../AgenciesDetails/UserOpeningHours/UserOpeningHours';
import { UserDefaultGingrSettings } from './DefaultGingrSettings/UserDefaultGingrSettings/UserDefaultGingrSettings';
import { fetchEstablishmentAction } from '../Establishments/establishment.action';
import { GeneralEstablishmentState } from '../Establishments/establishment.reducer';
import UserVideos from '../GingrDetails/GingrVideos/UserVideos';
import { DateFormatter, UserStatusUtil } from '../../../../utils/Utils';
import { APPROVAL_STATUS } from '../GingrDetails/GingrDetails';
import { fetchPendingApprovalUsersCount } from '../../Sidebar/sidebar.action';
import { countUserPhotosToBeApproved } from '../usersAssetsToBeApproved.action';

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: '#220720',
      color: '#8a7c88',
    },
  },
};
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#a0ff0f',
    color: '#000000',
    width: 10,
    height: 10,
    top: 18,
    right: 10,
    border: `0.5px solid #000000`,
  },
}));

const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
}));

export const EstablishmentsDetails: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const params: any = useParams();
  const [inProgress, setInProgress] = React.useState(false);

  const navigateToEstablishmentsList = () => {
    history.push(`/users/establishments`);
  };

  const clientListData = useSelector(
    (state: AppState) => state.data.establishment
  );
  const assetsToBeApproved = useSelector(
    (state: AppState) => state.data.user.assetsToBeApproved
  );
  const [clientList, setClientList] = useState<GeneralEstablishmentState[]>(
    clientListData.users?.users?.users
  );

  React.useEffect(() => {
    if (
      clientListData &&
      clientListData.users &&
      clientListData.users !== 'INCOMPLETE'
    ) {
      setClientList(clientListData.users.users);
    }
  }, [clientListData]);

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const [approvalProcess, setApprovalProcess] = React.useState({
    rejectButton: false,
    approveButton: false,
  });

  const [establishmentProfileDetails, setEstablishmentProfileDetails] =
    React.useState<GeneralEstablishmentProfileDetailsState>({
      id: 0,
      displayname: '',
      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      proUser: false,
      profileStatus: 0,
      createdAt: new Date(),
      slug: '',
      description: '',
      website: '',
      contact: '',
      booking_notes: '',
      availability: [],
      services: [],
      currency: '',
      entryFee: '',
      mobile: {
        prefix: '',
        number: '',
      },
      landline: {
        prefix: '',
        number: '',
      },
      avatar: {
        id: 0,
        media: '',
        thumb: '',
        thumbblur: '',
        type: '',
      },
      location: {
        formatted_address: '',
        longitude: 0,
        latitude: 0,
        country: '',
        city: '',
        isArea: 0,
        have_working_address: 0,
      },
    });

  React.useEffect(() => {
    if (params && params.id) {
      dispatch(fetchEstablishmentProfileDetailsAction(params.id));
      dispatch(countUserPhotosToBeApproved(params.id, 'PHOTOS'));
      dispatch(countUserPhotosToBeApproved(params.id, 'VIDEOS'));
    }
  }, [params]);

  const establishmentProfileDetailsData = useSelector(
    (state: AppState) => state.data.establishment
  );

  React.useEffect(() => {
    if (
      establishmentProfileDetailsData &&
      establishmentProfileDetailsData.profileDetails &&
      establishmentProfileDetailsData.profileDetails !== 'INCOMPLETE'
    ) {
      setEstablishmentProfileDetails(
        establishmentProfileDetailsData.profileDetails
      );
    }
  }, [establishmentProfileDetailsData]);

  const classes = useStyles();
  const [currentRoute, setCurrentRoute] = React.useState<
    UserDetailsRoutes | string
  >(UserDetailsRoutes.PERSONAL_DETAILS);
  const [profileDetails, setProfileDetails] = React.useState(
    UserDetailsRoutes.PERSONAL_DETAILS
  );
  const [defaultGingrSettings, setDefaultGingrSettings] = React.useState(
    UserDetailsRoutes.DEFAULT_GINGR_SETTINGS
  );

  const menuDropdownProfileDetailsActive = (route) => {
    const list = [
      UserDetailsRoutes.PERSONAL_DETAILS,
      UserDetailsRoutes.FACILITIES,
      UserDetailsRoutes.OPENING_HOURS,
    ];
    return list.includes(route);
  };
  const menuDropdownActive = (route) => {
    const list = [
      UserDetailsRoutes.DEFAULT_GINGR_SETTINGS,
      UserDetailsRoutes.AVAILABILITY,
      UserDetailsRoutes.PRICING,
      UserDetailsRoutes.SERVICES,
    ];
    return list.includes(route);
  };

  const handleProfileDetailsChange = (event) => {
    setProfileDetails(event.target.value);
    setCurrentRoute(event.target.value);
  };
  const handleDefaultGingrSettingsChange = (event) => {
    setDefaultGingrSettings(event.target.value);
    setCurrentRoute(event.target.value);
  };

  React.useEffect(() => {
    (async () => {
      await getApprovalStatus();
    })();
  }, [establishmentProfileDetails]);

  const handleApproveUser = async () => {
    if (inProgress) {
      return;
    }
    setInProgress(true)
    if (approvalProcess.approveButton) {
      const approveResponse = await AdminUserService.approveUser(
        establishmentProfileDetails.id,
        token
      );
      if (approveResponse.result && approveResponse.status === 200) {
        setEstablishmentProfileDetails({
          ...establishmentProfileDetails,
          profileStatus: 2,
        });
        dispatch(fetchPendingApprovalUsersCount());
        await AdminUserService.sendNotificationProfileApproved(token, establishmentProfileDetails.id)
        setInProgress(false)
      }
    }
  };

  const handleRefuseUser = async () => {
    if (inProgress) {
      return;
    }
    setInProgress(true)
    await AdminUserService.sendNotificationProfileNotApproved(token, establishmentProfileDetails.id)
    setInProgress(false)
  };

  const handleActiveRoute = (route: UserDetailsRoutes): boolean => {
    return route === currentRoute;
  };

  const navigateToBackClient = () => {
    if (clientList && clientList.length > 0) {
      const currentIndex = clientList.findIndex(
        (item) => item.id.toString() === params.id
      );
      if (
        currentIndex !== -1 &&
        clientList[currentIndex + 1] &&
        clientList[currentIndex + 1].id
      ) {
        history.push({
          pathname: `/users/establishments/${
            clientList[currentIndex + 1].id
          }/${currentRoute}`,
        });
        dispatch(
          fetchEstablishmentProfileDetailsAction(
            clientList[currentIndex + 1].id
          )
        );
        dispatch(
          countUserPhotosToBeApproved(clientList[currentIndex + 1].id, 'PHOTOS')
        );
        dispatch(
          countUserPhotosToBeApproved(clientList[currentIndex + 1].id, 'VIDEOS')
        );
      } else {
        navigateToEstablishmentsList();
      }
    }
  };

  const navigateToNextClient = () => {
    if (clientList && clientList.length > 0) {
      const currentIndex = clientList.findIndex(
        (item) => item.id.toString() === params.id
      );
      if (
        currentIndex !== -1 &&
        clientList[currentIndex - 1] &&
        clientList[currentIndex - 1].id
      ) {
        history.push({
          pathname: `/users/establishments/${
            clientList[currentIndex - 1].id
          }/${currentRoute}`,
        });
        dispatch(
          fetchEstablishmentProfileDetailsAction(
            clientList[currentIndex - 1].id
          )
        );
        dispatch(
          countUserPhotosToBeApproved(clientList[currentIndex - 1].id, 'PHOTOS')
        );
        dispatch(
          countUserPhotosToBeApproved(clientList[currentIndex - 1].id, 'VIDEOS')
        );
      } else {
        navigateToEstablishmentsList();
      }
    }
  };

  const getBackTitle = () => {
    if (clientList && clientList.length > 0) {
      const currentIndex = clientList.findIndex(
        (item) => item.id.toString() === params.id
      );
      if (
        currentIndex !== -1 &&
        clientList[currentIndex + 1] &&
        clientList[currentIndex + 1].displayname
      ) {
        return clientList[currentIndex + 1].displayname;
      } else {
        return ' ';
      }
    }
  };

  const getNextTitle = () => {
    if (clientList && clientList.length > 0) {
      const currentIndex = clientList.findIndex(
        (item) => item.id.toString() === params.id
      );
      if (
        currentIndex !== -1 &&
        clientList[currentIndex - 1] &&
        clientList[currentIndex - 1].displayname
      ) {
        return clientList[currentIndex - 1].displayname;
      } else {
        return ' ';
      }
    }
  };

  const getApprovalStatus = async () => {
    if (establishmentProfileDetails && establishmentProfileDetails.id !== 0) {
      const response: boolean =
        await GingrProfileDetailsService.getApprovalProcess(
          {
            token,
          },
          establishmentProfileDetails.id
        );
      setApprovalProcess({
        ...approvalProcess,
        approveButton: response,
      });
    }
  };

  const updateVideosToBeApproved = () => {
    dispatch(countUserPhotosToBeApproved(params.id, 'VIDEOS'));
  };

  React.useEffect(() => {
    const fullUrl = window.location.href;
    const currentSubPath = fullUrl.substring(fullUrl.lastIndexOf('/') + 1);
    setCurrentRoute(currentSubPath);
    if (clientListData.users === 'INCOMPLETE') {
      dispatch(fetchEstablishmentAction());
    }
  }, []);

  return (
    <div className="userDetailsContainer">
      <Header
        backTitle={getBackTitle()}
        onClickBack={navigateToBackClient}
        nextTitle={getNextTitle()}
        onClickNext={navigateToNextClient}
      />
      <div className="mainInfo">
        <div className="details" style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className="avatarContainer">
                <StyledBadge variant="dot">
                  <Avatar
                    src={establishmentProfileDetails.avatar.thumb}
                    className={classes.sizeAvatar}
                  />
                </StyledBadge>
                <div className="nameAndPayment">
                  <p>{establishmentProfileDetails.displayname}</p>
                  <ChartButton
                    text={
                      establishmentProfileDetails.proUser ? 'Premium' : 'Free'
                    }
                    isSelected={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={1.5} className="gridSpace">
              <p>{establishmentProfileDetails.email}</p>
              <span>Email</span>
            </Grid>
            <Grid item xs="auto" className="gridSpace">
              <p>
                {establishmentProfileDetails.mobile.number}
              </p>
              <span>Mobile</span>
            </Grid>
            <Grid item xs="auto" className="gridSpace">
              <p>
                {establishmentProfileDetails.landline.number}
              </p>
              <span>Landline</span>
            </Grid>
            <Grid item xs={1} className="gridSpace">
              <p>
                {establishmentProfileDetails.createdAt &&
                  DateFormatter(establishmentProfileDetails.createdAt as Date)}
              </p>
              <span>Registered</span>
            </Grid>
            <Grid item xs="auto" className="gridSpace">
              <SimpleButton
                text={
                  UserStatusUtil[establishmentProfileDetails.profileStatus].name
                }
                backgroundColor={
                  UserStatusUtil[establishmentProfileDetails.profileStatus]
                    .buttonColor
                }
                fullWidth={true}
                disabled={true}
              />
              <span style={{ display: 'block', marginTop: 12 }}>
                Profile Status
              </span>
            </Grid>
            <Grid item xs="auto" className="gridSpace">
              <SimpleButton
                text={'Verified'}
                fullWidth={true}
                disabled={true}
              />
              <span style={{ display: 'block', marginTop: 12 }}>
                Verification Status
              </span>
            </Grid>
            <Grid item className="gridSpace">
              <SimpleButton
                text={'Refuse Profile'}
                backgroundColor={'#ff3a3a'}
                fullWidth={true}
                disabled={true}
                onClick={handleRefuseUser}
              />
              <SimpleButton
                marginTop={8}
                text={'Approve Profile'}
                fullWidth={true}
                backgroundColor={
                  !approvalProcess.approveButton ? '#8a8a86' : '#2e7d32'
                }
                onClick={handleApproveUser}
                disabled={!approvalProcess.approveButton}
              />
            </Grid>
            <Grid item xs={1} className="gridSpace">
              <div className="edit">
                <OptionsIcon className="editIcons" />
                <EditIcon className="editIcons" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content">
        <div className="sidebar">
          <ul className="sidebar-menu" style={{ width: 220 }}>
            <li>
              <span
                className={`sidebar-menu-item ${
                  menuDropdownProfileDetailsActive(currentRoute) ? 'active' : ''
                }`}
              >
                <Select
                  value={profileDetails}
                  displayEmpty
                  onChange={handleProfileDetailsChange}
                  MenuProps={MenuProps}
                  disableUnderline
                  placeholder="Profile Details"
                  defaultValue="Profile Details"
                  className={`${
                    menuDropdownProfileDetailsActive(currentRoute)
                      ? 'active-select'
                      : ''
                  }`}
                >
                  <ListSubheader
                    value={UserDetailsRoutes.PERSONAL_DETAILS}
                    onClick={() =>
                      history.push(
                        `${url}/${UserDetailsRoutes.PERSONAL_DETAILS}`
                      )
                    }
                  >
                    Profile Details
                  </ListSubheader>
                  <MenuItem
                    value={UserDetailsRoutes.FACILITIES}
                    onClick={() =>
                      history.push(`${url}/${UserDetailsRoutes.FACILITIES}`)
                    }
                  >
                    Facilities
                  </MenuItem>
                  <MenuItem
                    value={UserDetailsRoutes.OPENING_HOURS}
                    onClick={() =>
                      history.push(`${url}/${UserDetailsRoutes.OPENING_HOURS}`)
                    }
                  >
                    Opening Hours
                  </MenuItem>
                </Select>
              </span>
            </li>
            <li>
              <LinkItem
                url={url}
                notificationNumber={assetsToBeApproved.photosToReview}
                sub={UserDetailsRoutes.PHOTOS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.PHOTOS)}
                isActive={handleActiveRoute(UserDetailsRoutes.PHOTOS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                notificationNumber={assetsToBeApproved.videosToReview}
                sub={UserDetailsRoutes.VIDEOS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.VIDEOS)}
                isActive={handleActiveRoute(UserDetailsRoutes.VIDEOS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.MANAGE_GINGRS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.MANAGE_GINGRS)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.MANAGE_GINGRS)}
              />
            </li>
            <li>
              <span
                className={`sidebar-menu-item ${
                  menuDropdownActive(currentRoute) ? 'active' : ''
                }`}
              >
                <Select
                  value={defaultGingrSettings}
                  displayEmpty
                  onChange={handleDefaultGingrSettingsChange}
                  MenuProps={MenuProps}
                  disableUnderline
                  placeholder="Default Gingr Settings"
                  defaultValue="Default Gingr Settings"
                  className={`${
                    menuDropdownActive(currentRoute) ? 'active-select' : ''
                  }`}
                >
                  <ListSubheader
                    value={UserDetailsRoutes.DEFAULT_GINGR_SETTINGS}
                  >
                    Default Gingr Settings
                  </ListSubheader>
                  <MenuItem
                    value={UserDetailsRoutes.AVAILABILITY}
                    onClick={() =>
                      history.push(`${url}/${UserDetailsRoutes.AVAILABILITY}`)
                    }
                  >
                    Availability
                  </MenuItem>
                  <MenuItem
                    value={UserDetailsRoutes.PRICING}
                    onClick={() =>
                      history.push(`${url}/${UserDetailsRoutes.PRICING}`)
                    }
                  >
                    Pricing
                  </MenuItem>
                  <MenuItem
                    value={UserDetailsRoutes.SERVICES}
                    onClick={() =>
                      history.push(`${url}/${UserDetailsRoutes.SERVICES}`)
                    }
                  >
                    Services
                  </MenuItem>
                </Select>
              </span>
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.BOOKINGS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.BOOKINGS)}
                isActive={handleActiveRoute(UserDetailsRoutes.BOOKINGS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.SUBSCRIPTIONS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.SUBSCRIPTIONS)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.SUBSCRIPTIONS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.REFERRALS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.REFERRALS)}
                isActive={handleActiveRoute(UserDetailsRoutes.REFERRALS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.VERIFICATION}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.VERIFICATION)}
                isActive={handleActiveRoute(UserDetailsRoutes.VERIFICATION)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.REVIEWS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.REVIEWS)}
                isActive={handleActiveRoute(UserDetailsRoutes.REVIEWS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.ACTIVITIES}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.ACTIVITIES)}
                isActive={handleActiveRoute(UserDetailsRoutes.ACTIVITIES)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.SECURITY}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.SECURITY)}
                isActive={handleActiveRoute(UserDetailsRoutes.SECURITY)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.WALLET_MANAGEMENT}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.WALLET_MANAGEMENT)
                }
                isActive={handleActiveRoute(
                  UserDetailsRoutes.WALLET_MANAGEMENT
                )}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.TIER_ALLOCATION}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.TIER_ALLOCATION)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.TIER_ALLOCATION)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.COMPLIANCE_STATUS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.COMPLIANCE_STATUS)
                }
                isActive={handleActiveRoute(
                  UserDetailsRoutes.COMPLIANCE_STATUS
                )}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.EXPORT_FUNCTION}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.EXPORT_FUNCTION)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.EXPORT_FUNCTION)}
              />
            </li>
          </ul>
        </div>
        <div style={{ width: '100%' }}>
          <Switch>
            <Route path={`${url}/${UserDetailsRoutes.PHOTOS}`} exact>
              <UserPhotos
                photosToReview={assetsToBeApproved.photosToReview}
                callbackStatus={getApprovalStatus}
                userId={params.id}
                profileStatus={establishmentProfileDetails.profileStatus}
              />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.VIDEOS}`}>
              <UserVideos
                videosToReview={assetsToBeApproved.videosToReview}
                callbackStatus={getApprovalStatus}
                updateVideosToBeApproved={updateVideosToBeApproved}
                userId={params.id}
                profileStatus={establishmentProfileDetails.profileStatus}
              />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.BOOKINGS}`} />
            <Route path={`${url}/${UserDetailsRoutes.SUBSCRIPTIONS}`} />
            <Route path={`${url}/${UserDetailsRoutes.REFERRALS}`} />
            <Route path={`${url}/${UserDetailsRoutes.VERIFICATION}`} />
            <Route path={`${url}/${UserDetailsRoutes.REVIEWS}`} />
            <Route path={`${url}/${UserDetailsRoutes.ACTIVITIES}`} />
            <Route path={`${url}/${UserDetailsRoutes.SECURITY}`} />
            <Route path={`${url}/${UserDetailsRoutes.TIER_ALLOCATION}`} />
            <Route path={`${url}/${UserDetailsRoutes.COMPLIANCE_STATUS}`} />
            <Route path={`${url}/${UserDetailsRoutes.EXPORT_FUNCTION}`} />
            <Route path={`${url}`}>
              <Route path={`${url}/${UserDetailsRoutes.PERSONAL_DETAILS}`}>
                <UserPersonalDetails data={establishmentProfileDetails} />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.FACILITIES}`} exact>
                <UserFacilities data={establishmentProfileDetails.services} />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.OPENING_HOURS}`} exact>
                <UserOpeningHours
                  data={establishmentProfileDetails.availability}
                />
              </Route>
            </Route>
            <Route path={`${url}`}>
              <Route
                path={`${url}/${UserDetailsRoutes.DEFAULT_GINGR_SETTINGS}`}
              >
                <UserDefaultGingrSettings />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.PRICING}`} exact>
                <UserPricing data={establishmentProfileDetails} />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.SERVICES}`} exact>
                <UserServices data={establishmentProfileDetails.services} />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.AVAILABILITY}`} exact>
                <UserAvailability />
              </Route>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
