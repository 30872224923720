import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { EstablishmentService } from './EstablishmentService';

import { GeneralEstablishmentState } from './establishment.reducer';
import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getCredentials } from '../../../../shared/selectors/getCredentials';

export type EstablibshmentActionType = 'USERS';

export type EstablishmentAction = 'ADD_ESTABLISHMENT' | 'CLEAR_ESTABLISHMENT';

export interface AddEstablishmentAction extends Action<EstablishmentAction> {
  type: 'ADD_ESTABLISHMENT';
  users: GeneralEstablishmentState[];
}

export interface ClearEstablishmentAction extends Action<EstablishmentAction> {
  type: 'CLEAR_ESTABLISHMENT';
}

export const addUsers = (
  users: GeneralEstablishmentState[]
): AddEstablishmentAction => ({
  type: 'ADD_ESTABLISHMENT',
  users,
});

export const clearAgentInformation = (): ClearEstablishmentAction => ({
  type: 'CLEAR_ESTABLISHMENT',
});

export const fetchEstablishmentAction =
  (filters?: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('USERS'));
    let response: any;
    const credentials = getCredentials(getState());
    let finalFilter = '';
    if (filters) {
      const newData = {
        country: filters.country.id,
        connectedGingrs: filters.connectedGingrs.id,
        proUser: filters.proUser.id,
        onlyOnlineUsers: filters.onlyOnlineUsers,
        type: filters.type.id,
        bookingsNumber: filters.bookingsNumber.name,
        profileStatus: filters.profileStatus.id,
        verificationStatus: filters.verificationStatus.id,
      };

      if (newData.country) {
        finalFilter = finalFilter + '&country=' + newData.country;
      }
      if (newData.connectedGingrs) {
        finalFilter =
          finalFilter + '&connectedGingrs=' + newData.connectedGingrs;
      }
      if (newData.proUser) {
        finalFilter = finalFilter + '&proUser=' + newData.proUser;
      }
      if (newData.onlyOnlineUsers) {
        finalFilter = finalFilter + '&online=' + newData.onlyOnlineUsers;
      }
      if (newData.type) {
        finalFilter = finalFilter + '&type=' + newData.type;
      }
      if (newData.bookingsNumber) {
        finalFilter =
          finalFilter + '&bookingsFrom=' + newData.bookingsNumber.split('-')[0];
        finalFilter =
          finalFilter + '&bookingsTo=' + newData.bookingsNumber.split('-')[1];
      }
      if (newData.profileStatus) {
        finalFilter = finalFilter + '&profileStatus=' + newData.profileStatus;
      }
    }
    const filters1 = finalFilter;
    console.log(filters1);
    try {
      response = await EstablishmentService.getEstablishment(
        credentials,
        filters1
      );
      dispatch(addUsers(response));
    } catch (error) {
      console.log('Error during add users action: ', error);
    }
    dispatch(loadComplete('ESTABLISHMENT_FETCH'));
  };
