import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { EstablishmentProfileDetailsService } from './EstablishmentProfileDetailsService';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import {
  Avatar,
  GeneralEstablishmentProfileDetailsState,
  Landline,
  Location,
  Mobile,
} from './establishmentProfileDetails.reducer';
import {
  Availability,
  Service,
} from '../AgenciesDetails/agencyProfileDetails.reducer';

export type EstablishmentProfileDetailsActionType =
  'ESTABLISHMENT_PROFILE_DETAILS';

export type EstablishmentProfileDetailsAction =
  | 'ADD_ESTABLISHMENT_PROFILE_DETAILS'
  | 'CLEAR_ESTABLISHMENT_PROFILE_DETAILS';

export interface AddEstablishmentProfileDetailsAction
  extends Action<EstablishmentProfileDetailsAction> {
  type: 'ADD_ESTABLISHMENT_PROFILE_DETAILS';
  id: number;
  displayname: string;
  companyName: any;
  firstName: any;
  lastName: any;
  email: string;
  proUser: boolean;
  createdAt: Date;
  slug: string;
  description: string;
  website: any;
  contact: any;
  booking_notes: string;
  availability: Availability[];
  services: Service[];
  currency: string;
  entryFee: any;
  profileStatus: number;
  mobile: Mobile;
  landline: Landline;
  avatar: Avatar;
  location: Location;
}

export interface ClearEstablishmentProfileDetailsAction
  extends Action<EstablishmentProfileDetailsAction> {
  type: 'CLEAR_ESTABLISHMENT_PROFILE_DETAILS';
}

export const addEstablishmentProfileDetails = (
  id: number,
  displayname: string,
  companyName: any,
  firstName: any,
  lastName: any,
  email: string,
  proUser: boolean,
  profileStatus: number,
  createdAt: Date,
  slug: string,
  description: string,
  website: any,
  contact: any,
  booking_notes: string,
  availability: Availability[],
  services: Service[],
  currency: string,
  entryFee: any,
  mobile: Mobile,
  landline: Landline,
  avatar: Avatar,
  location: Location
): AddEstablishmentProfileDetailsAction => ({
  type: 'ADD_ESTABLISHMENT_PROFILE_DETAILS',
  id,
  displayname,
  companyName,
  firstName,
  lastName,
  email,
  proUser,
  profileStatus,
  createdAt,
  slug,
  description,
  website,
  contact,
  booking_notes,
  availability,
  services,
  currency,
  entryFee,
  mobile,
  landline,
  avatar,
  location,
});

export const clearAgentInformation =
  (): ClearEstablishmentProfileDetailsAction => ({
    type: 'CLEAR_ESTABLISHMENT_PROFILE_DETAILS',
  });

export const fetchEstablishmentProfileDetailsAction =
  (id: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('USER_PROFILE_DETAILS'));
    let response: GeneralEstablishmentProfileDetailsState;
    const credentials = getCredentials(getState());
    const id1 = id;
    try {
      response =
        await EstablishmentProfileDetailsService.getEstablishmentProfileDetails(
          credentials,
          id1
        );
      const {
        id,
        displayname,
        companyName,
        firstName,
        lastName,
        email,
        proUser,
        profileStatus,
        createdAt,
        slug,
        description,
        website,
        contact,
        booking_notes,
        availability,
        services,
        currency,
        entryFee,
        mobile,
        landline,
        avatar,
        location,
      } = response;
      dispatch(
        addEstablishmentProfileDetails(
          id,
          displayname,
          companyName,
          firstName,
          lastName,
          email,
          proUser,
          profileStatus,
          createdAt,
          slug,
          description,
          website,
          contact,
          booking_notes,
          availability,
          services,
          currency,
          entryFee,
          mobile,
          landline,
          avatar,
          location
        )
      );
    } catch (error) {
      console.log('Error during add users action: ', error);
    }
    dispatch(loadComplete('ESTABLISHMENT_PROFILE_DETAILS_FETCH'));
  };
