import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../../rootReducer';
import {
  loadComplete,
  loadStarted,
} from '../../../../../shared/loading.action';
import { UserProfileDetailsService } from './UserProfileDetailsService';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';
import {
  Avatar,
  GeneralUserProfileDetailsState,
} from './userProfileDetails.reducer';

export type UserProfileDetailsActionType = 'USER_PROFILE_DETAILS';

export type UserProfileDetailsAction =
  | 'ADD_USER_PROFILE_DETAILS'
  | 'CLEAR_USER_PROFILE_DETAILS';

export interface AddUserProfileDetailsAction
  extends Action<UserProfileDetailsAction> {
  type: 'ADD_USER_PROFILE_DETAILS';
  id: number;
  slug: string;
  email: string;
  proUser: boolean;
  displayname: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
  dateOfBirth: string;
  birthYear: number;
  gender: number;
  nationality: string;
  sexuality: number;
  phonePrefix: string;
  phoneNumber: string;
  profileStatus: number;
  description: string;
  height: number;
  weight: number;
  penisSize: number;
  genitaliaArea: string;
  hear: string;
  hearText: string;
  avatar: Avatar;
}

export interface ClearUserProfileDetailsAction
  extends Action<UserProfileDetailsAction> {
  type: 'CLEAR_USER_PROFILE_DETAILS';
}

export const addUserProfileDetails = (
  id: number,
  slug: string,
  email: string,
  proUser: boolean,
  displayname: string,
  firstName: string,
  lastName: string,
  createdAt: Date,
  dateOfBirth: string,
  birthYear: number,
  gender: number,
  nationality: string,
  sexuality: number,
  phonePrefix: string,
  phoneNumber: string,
  profileStatus: number,
  description: string,
  height: number,
  weight: number,
  penisSize: number,
  genitaliaArea: string,
  hear,
  hearText: string,
  avatar: Avatar
): AddUserProfileDetailsAction => ({
  type: 'ADD_USER_PROFILE_DETAILS',
  id,
  slug,
  email,
  proUser,
  displayname,
  firstName,
  lastName,
  createdAt,
  dateOfBirth,
  birthYear,
  gender,
  nationality,
  sexuality,
  phonePrefix,
  phoneNumber,
  profileStatus,
  description,
  height,
  weight,
  penisSize,
  genitaliaArea,
  hear,
  hearText,
  avatar,
});

export const clearAgentInformation = (): ClearUserProfileDetailsAction => ({
  type: 'CLEAR_USER_PROFILE_DETAILS',
});

export const fetchUserProfileDetailsAction =
  (id: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('USER_PROFILE_DETAILS'));
    let response: GeneralUserProfileDetailsState;
    const credentials = getCredentials(getState());
    const id1 = id;
    try {
      response = await UserProfileDetailsService.getUserProfileDetails(
        credentials,
        id1
      );
      const {
        id,
        slug,
        email,
        proUser,
        displayname,
        firstName,
        lastName,
        createdAt,
        dateOfBirth,
        birthYear,
        gender,
        nationality,
        sexuality,
        phonePrefix,
        phoneNumber,
        profileStatus,
        description,
        height,
        weight,
        penisSize,
        genitaliaArea,
        hear,
        hearText,
        avatar,
      } = response;
      dispatch(
        addUserProfileDetails(
          id,
          slug,
          email,
          proUser,
          displayname,
          firstName,
          lastName,
          createdAt,
          dateOfBirth,
          birthYear,
          gender,
          nationality,
          sexuality,
          phonePrefix,
          phoneNumber,
          profileStatus,
          description,
          height,
          weight,
          penisSize,
          genitaliaArea,
          hear,
          hearText,
          avatar
        )
      );
    } catch (error) {
      console.log('Error during add users count action: ', error);
    }
    dispatch(loadComplete('USERS_COUNT_FETCH'));
  };
