import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';
import { GeneralEstablishmentProfileDetailsState } from './establishmentProfileDetails.reducer';
import { GingrImagesToBeApproved } from '../GingrDetails/GingrPhotos/userPhotos.reducer';

const ProductServicePaths = {
  ESTABLISHMENT_PROFILE_DETAILS: '/admin/users/establishment/',
  ESTABLISHMENT_IMAGES: (id: string) => `/admin/medias/users/${id}/image`,
};

export class EstablishmentProfileDetailsService {
  static async getEstablishmentProfileDetails(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<GeneralEstablishmentProfileDetailsState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.ESTABLISHMENT_PROFILE_DETAILS + id,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
  static async getEstablishmentImages(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<Array<GingrImagesToBeApproved>> {
    const { result } = await GingrClient.get(
      ProductServicePaths.ESTABLISHMENT_IMAGES(id),
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
}
