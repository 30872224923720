import { Action } from 'redux';
import produce from 'immer';
import { AddEstablishmentAction } from './establishment.action';

export interface GeneralEstablishmentState {
  id: number;
  avatarThumb?: string;
  slug: string;
  email: string;
  displayname?: string;
  bookings: number;
  companyName?: string;
  profile_approved: number;
  connectedGingrs: number;
  type: string;
  proUser: boolean;
  created_at: string;
  online: boolean;
}

export type EstablishmentAction = 'ADD_ESTABLISHMENT' | 'CLEAR_ESTABLISHMENT';

export type EstablishmentState = 'INCOMPLETE' | GeneralEstablishmentState[];

export const establishmentReducer = (
  state: EstablishmentState = 'INCOMPLETE',
  action: Action<EstablishmentAction>
): EstablishmentState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_ESTABLISHMENT':
        const addEstablishmentAction = action as AddEstablishmentAction;
        const { users } = addEstablishmentAction;
        return {
          users,
        };
      case 'CLEAR_ESTABLISHMENT':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
