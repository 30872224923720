import React, {useEffect, useState} from 'react';
import { Header } from '../../../Elements/Header/Header';
import { Avatar, Badge, makeStyles, styled, Grid } from '@material-ui/core';
import { ChartButton } from '../../Dashboard/ChartButton';
import OptionsIcon from '../../../../assets/img/icon/options.svg';
import EditIcon from '../../../../assets/img/icon/edit.svg';
import './ClientDetails.scss';
import { UserPersonalDetails } from './UserPersonalDetails/UserPersonalDetails';
import { SimpleButton } from '../../Revenue/SimpleButton/SimpleButton';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralUserProfileDetailsState } from './UserPersonalDetails/userProfileDetails.reducer';
import { fetchUserProfileDetailsAction } from './UserPersonalDetails/userProfileDetails.action';
import { AppState } from '../../../../rootReducer';
import { LinkItem } from '../LinkItem/LinkItem';
import { UserDetailsRoutes } from '../../Layouts/MainLayout/MainLayout';
import { AdminUserService } from '../../../../service/AdminUserService';
import { GeneralUsersState } from '../Clients/clients.reducer';
import { DateFormatter, UserStatusUtil } from '../../../../utils/Utils';
import UserPhotos from '../GingrDetails/GingrPhotos/UserPhotos';
import { GingrProfileDetailsService } from '../GingrDetails/GingrProfileDetailsService';
import { countUserPhotosToBeApproved } from '../usersAssetsToBeApproved.action';
import { fetchPendingApprovalUsersCount } from '../../Sidebar/sidebar.action';
import UserVerification from "../GingrDetails/GingrVerification/UserVerification";
import {GingrProfileDetails} from "../GingrDetails/gingrProfileDetails";
import {GingrService} from "../Gingrs/GingrService";

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#a0ff0f',
    color: '#000000',
    width: 10,
    height: 10,
    top: 18,
    right: 10,
    border: `0.5px solid #000000`,
  },
}));

const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
}));

export const ClientDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const clientListData = useSelector((state: AppState) => state.data.clients);
  const assetsToBeApproved = useSelector(
    (state: AppState) => state.data.user.assetsToBeApproved
  );
  const [inProgress, setInProgress] = React.useState(false);
  const [approvalProcess, setApprovalProcess] = React.useState({
    rejectButton: false,
    approveButton: false,
  });
  // @ts-ignore
  const [loadingApproval, setLoadingApproval] = React.useState<boolean>(false);

  const [currentRoute, setCurrentRoute] = React.useState<
    UserDetailsRoutes | string
  >(UserDetailsRoutes.PROFILE_DETAILS);

  const [userProfileDetails, setUserProfileDetails] =
    React.useState<GingrProfileDetails>({
      id: 0,
      slug: '',
      email: '',
      proUser: false,
      displayname: '',
      firstName: '',
      lastName: '',
      createdAt: new Date(),
      birthYear: 0,
      gender: 0,
      nationality: '',
      sexuality: 0,
      phonePrefix: '',
      phoneNumber: '',
      profileStatus: 0,
      description: '',
      height: 0,
      weight: 0,
      penisSize: 0,
      genitaliaArea: '',
      hear: '',
      hearText: '',
      avatar: {
        media: '',
        thumb: '',
        thumbblur: '',
        type: '',
      },
    });
  const { url } = useRouteMatch();

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const userId = history.location.pathname.split('/')
    ? history.location.pathname.split('/')[3]
    : '';
  const params: any = useParams();

  const navigateToClientList = () => {
    history.push(`/users/clients`);
  };

  React.useEffect(() => {
    getApprovalStatus();
    if (userId || params.id) {
      dispatch(fetchUserProfileDetailsAction(userId ? userId : params.id));
      dispatch(
        countUserPhotosToBeApproved(userId ? userId : params.id, 'PHOTOS')
      );
      dispatch(countUserPhotosToBeApproved(userId ? userId : params.id, 'SELFIES'));
    }
  }, [userId, params]);


  const classes = useStyles();

  const getUserList = async (userId: string) => {
    const data = await GingrService.getUser(token, userId);
    if (data) {
      setUserProfileDetails(data);
      const fetchData = async () => {
        const response: boolean =
            await GingrProfileDetailsService.getApprovalProcess(
                {
                  token,
                },
                data?.id
            );
        setApprovalProcess({
          ...approvalProcess,
          approveButton: response,
        });
      };

      fetchData().catch(console.error);
    }
  };

  useEffect(() => {
    (async () => {
      await getUserList(params.id)    })();
  }, [params]);

  const handleActiveRoute = (route: UserDetailsRoutes): boolean => {
    return route === currentRoute;
  };

  const getApprovalStatus = async (): Promise<void> => {
    if (userProfileDetails && userProfileDetails.id !== 0) {
      const response: boolean =
        await GingrProfileDetailsService.getApprovalProcess(
          {
            token,
          },
          userProfileDetails.id
        );
      setApprovalProcess({
        ...approvalProcess,
        approveButton: response,
      });
    }
  };

  const handleApproveUser = async () => {
    if (inProgress) {
      return;
    }
    setInProgress(true)
    if (userProfileDetails.slug && token && userProfileDetails.profileStatus) {
      setLoadingApproval(true);
      const approveResponse = await AdminUserService.approveUser(
        userProfileDetails.id,
        token
      );
      if (approveResponse.result && approveResponse.status === 200) {
        setUserProfileDetails({
          ...userProfileDetails,
          profileStatus: 2,
        });
        dispatch(fetchPendingApprovalUsersCount());
        await AdminUserService.sendNotificationProfileApproved(token, userProfileDetails.id)
        setInProgress(false)
        setTimeout(() => {
          setLoadingApproval(false);
        }, 300);
      }
    }
  };

  const handleRefuseUser = async () => {
    if (inProgress) {
      return;
    }
    setInProgress(true)
    await AdminUserService.sendNotificationProfileNotApproved(token, userProfileDetails.id)
    setInProgress(false)
  };

  return (
    <div className="userDetailsContainer">
      <div className="mainInfo">
        <div className="details" style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className="avatarContainer">
                <StyledBadge variant="dot">
                  <Avatar
                    src={userProfileDetails.avatarThumb}
                    className={classes.sizeAvatar}
                  />
                </StyledBadge>
                <div className="nameAndPayment">
                  <p>{userProfileDetails.displayName}</p>
                  <ChartButton
                    text={userProfileDetails.proUser ? 'Premium' : 'Free'}
                    isSelected={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={1.5} className="gridSpace">
              <p>{userProfileDetails.email}</p>
              <span>Email</span>
            </Grid>
            <Grid item xs="auto" className="gridSpace">
              <p>
                {userProfileDetails.mobile}
              </p>
              <span>Phone</span>
            </Grid>
            <Grid item xs={1.5} className="gridSpace">
              <p>{DateFormatter(userProfileDetails.createdAt as Date)}</p>
              <span>Registered</span>
            </Grid>
            <Grid
              item
              xs="auto"
              justifyContent="center"
              alignItems="center"
              className="gridSpace"
            >
              <SimpleButton
                text={UserStatusUtil[userProfileDetails.profileStatus].name}
                fullWidth={true}
                backgroundColor={
                  UserStatusUtil[userProfileDetails.profileStatus].buttonColor
                }
                disabled={true}
              />
              <span style={{ display: 'block', marginTop: 12 }}>
                Profile Status
              </span>
            </Grid>
            <Grid
              item
              xs="auto"
              justifyContent="center"
              alignItems="center"
              className="gridSpace"
            >
              <SimpleButton
                text={'Verified'}
                fullWidth={true}
                disabled={true}
              />
              <span style={{ display: 'block', marginTop: 12 }}>
                Verification Status
              </span>
            </Grid>
            <Grid item xs={1.5} alignItems="center" className="gridSpace">
              <SimpleButton
                text={'Refuse Profile'}
                backgroundColor={'#ff3a3a'}
                fullWidth={true}
                onClick={handleRefuseUser}
              />
              <SimpleButton
                text={'Approve Profile'}
                loading={loadingApproval}
                fullWidth={true}
                backgroundColor={
                  userProfileDetails.profileStatus !== 1 ? '#8a8a86' : '#2e7d32'
                }
                marginTop={8}
                onClick={handleApproveUser}
                disabled={!approvalProcess.approveButton}
              />
            </Grid>
            <Grid item xs={1} className="gridSpace">
              <div className="edit">
                <OptionsIcon className="editIcons" />
                <EditIcon className="editIcons" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content">
        <div className="sidebar">
          <ul className="sidebar-menu">
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.PERSONAL_DETAILS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.PERSONAL_DETAILS)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.PERSONAL_DETAILS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                notificationNumber={assetsToBeApproved.photosToReview}
                sub={UserDetailsRoutes.PHOTOS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.PHOTOS)}
                isActive={handleActiveRoute(UserDetailsRoutes.PHOTOS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.BOOKINGS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.BOOKINGS)}
                isActive={handleActiveRoute(UserDetailsRoutes.BOOKINGS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.SUBSCRIPTIONS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.SUBSCRIPTIONS)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.SUBSCRIPTIONS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.REFERRALS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.REFERRALS)}
                isActive={handleActiveRoute(UserDetailsRoutes.REFERRALS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.VERIFICATION}
                notificationNumber={assetsToBeApproved.selfiesToRevew}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.VERIFICATION)}
                isActive={handleActiveRoute(UserDetailsRoutes.VERIFICATION)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.REVIEWS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.REVIEWS)}
                isActive={handleActiveRoute(UserDetailsRoutes.REVIEWS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.ACTIVITIES}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.ACTIVITIES)}
                isActive={handleActiveRoute(UserDetailsRoutes.ACTIVITIES)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.SECURITY}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.SECURITY)}
                isActive={handleActiveRoute(UserDetailsRoutes.SECURITY)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.WALLET_MANAGEMENT}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.WALLET_MANAGEMENT)
                }
                isActive={handleActiveRoute(
                  UserDetailsRoutes.WALLET_MANAGEMENT
                )}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.TIER_ALLOCATION}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.TIER_ALLOCATION)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.TIER_ALLOCATION)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.COMPLIANCE_STATUS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.COMPLIANCE_STATUS)
                }
                isActive={handleActiveRoute(
                  UserDetailsRoutes.COMPLIANCE_STATUS
                )}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.EXPORT_FUNCTION}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.EXPORT_FUNCTION)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.EXPORT_FUNCTION)}
              />
            </li>
          </ul>
        </div>
        <div style={{ width: '100%' }}>
          <Switch>
            <Route path={`${url}/${UserDetailsRoutes.PHOTOS}`} exact>
              <UserPhotos
                photosToReview={assetsToBeApproved.photosToReview}
                callbackStatus={getApprovalStatus}
                userId={params.id}
                profileStatus={userProfileDetails.profileStatus}
              />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.PERSONAL_DETAILS}`}>
              <UserPersonalDetails data={userProfileDetails} />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.BOOKINGS}`} />
            <Route path={`${url}/${UserDetailsRoutes.SUBSCRIPTIONS}`} />
            <Route path={`${url}/${UserDetailsRoutes.REFERRALS}`} />
            <Route path={`${url}/${UserDetailsRoutes.VERIFICATION}`}>
              <UserVerification
                  selfiesToRevew={assetsToBeApproved.selfiesToRevew}
                  callbackStatus={getApprovalStatus}
                  userId={params.id}
                  profileStatus={userProfileDetails.profileStatus}
              />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.REVIEWS}`} />
            <Route path={`${url}/${UserDetailsRoutes.ACTIVITIES}`} />
            <Route path={`${url}/${UserDetailsRoutes.SECURITY}`} />
            <Route path={`${url}/${UserDetailsRoutes.WALLET_MANAGEMENT}`} />
            <Route path={`${url}/${UserDetailsRoutes.TIER_ALLOCATION}`} />
            <Route path={`${url}/${UserDetailsRoutes.COMPLIANCE_STATUS}`} />
            <Route path={`${url}/${UserDetailsRoutes.EXPORT_FUNCTION}`} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
