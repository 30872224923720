import { Action } from 'redux';
import produce from 'immer';
import { AddAgencyAction } from './agency.action';

export interface GeneralAgencyState {
  id: number;
  avatarThumb?: string;
  slug: string;
  email: string;
  displayname?: string;
  bookings: number;
  companyName?: string;
  profile_approved: number;
  connectedGingrs: number;
  type: string;
  proUser: boolean;
  created_at: string;
  online: boolean;
}

export type AgencyAction = 'ADD_AGENCY' | 'CLEAR_AGENCY';

export type AgencyState = 'INCOMPLETE' | GeneralAgencyState[];

export const agencyReducer = (
  state: AgencyState = 'INCOMPLETE',
  action: Action<AgencyAction>
): AgencyState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_AGENCY':
        const addAgencyAction = action as AddAgencyAction;
        const { users } = addAgencyAction;
        return {
          users,
        };
      case 'CLEAR_AGENCY':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
