import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { GeneralUsersState } from './clients.reducer';
import { ClientService } from './ClientService';

export type UsersActionType = 'USERS';

export type UsersAction = 'ADD_USERS' | 'CLEAR_USERS';

export interface AddUsersAction extends Action<UsersAction> {
  type: 'ADD_USERS';
  users: GeneralUsersState[];
}

export interface ClearUsersAction extends Action<UsersAction> {
  type: 'CLEAR_USERS';
}

export const addUsers = (users: GeneralUsersState[]): AddUsersAction => ({
  type: 'ADD_USERS',
  users,
});

export const clearAgentInformation = (): ClearUsersAction => ({
  type: 'CLEAR_USERS',
});

export const fetchUsersAction =
  (filters?: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('USERS'));
    let response: any;
    const credentials = getCredentials(getState());
    let finalFilter = '';
    if (filters) {
      const newData = {
        gender: filters.gender.id,
        proUser: filters.proUser.id,
        onlyOnlineUsers: filters.onlyOnlineUsers,
        sexuality: filters.sexuality.id,
        birthYear: filters.birthYear.name,
        bookingsNumber: filters.bookingsNumber.name,
        profileStatus: filters.profileStatus.id,
        verificationStatus: filters.verificationStatus.id,
      };

      if (newData.gender) {
        finalFilter = finalFilter + '&gender=' + newData.gender;
      }
      if (newData.proUser) {
        finalFilter = finalFilter + '&proUser=' + newData.proUser;
      }
      if (newData.onlyOnlineUsers) {
        finalFilter = finalFilter + '&online=' + newData.onlyOnlineUsers;
      }
      if (newData.bookingsNumber) {
        finalFilter =
          finalFilter + '&bookingsFrom=' + newData.bookingsNumber.split('-')[0];
        finalFilter =
          finalFilter + '&bookingsTo=' + newData.bookingsNumber.split('-')[1];
      }
      if (newData.birthYear) {
        finalFilter = finalFilter + '&birthYear=' + newData.birthYear;
      }
      if (newData.profileStatus) {
        finalFilter = finalFilter + '&profileStatus=' + newData.profileStatus;
      }
      if (newData.sexuality) {
        finalFilter = finalFilter + '&sexuality=' + newData.sexuality;
      }
    }
    const filters1 = finalFilter;

    try {
      response = await ClientService.getUsers(credentials, filters1);
      dispatch(addUsers(response));
    } catch (error) {
      console.log('Error during add users action: ', error);
    }
    dispatch(loadComplete('USERS_FETCH'));
  };
