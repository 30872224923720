import React, {useEffect, useState} from 'react';
import { Header } from '../../../Elements/Header/Header';
import {
  Avatar,
  Badge,
  Grid,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  styled,
} from '@material-ui/core';
import { ChartButton } from '../../Dashboard/ChartButton';
import OptionsIcon from '../../../../assets/img/icon/options.svg';
import EditIcon from '../../../../assets/img/icon/edit.svg';
import './GingrDetails.scss';
import { SimpleButton } from '../../Revenue/SimpleButton/SimpleButton';
import {
  useRouteMatch,
  Route,
  Switch,
  RouteComponentProps,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { GeneralGingrProfileDetailsState } from './gingrProfileDetails.reducer';
import { fetchGingrProfileDetailsAction } from './gingrProfileDetails.action';
import { GingrProfileDetailsService } from './GingrProfileDetailsService';
import { AdminUserService } from '../../../../service/AdminUserService';
import { LinkItem } from '../LinkItem/LinkItem';
import { UserDetailsRoutes } from '../../Layouts/MainLayout/MainLayout';
import { UserPersonalDetails } from './GingrProfileDetails/UserPersonalDetails/UserPersonalDetails';
import { UserPhysicalDetails } from './GingrProfileDetails/UserPhysicalDetails/UserPhysicalDetails';
import { UserPricing } from './GingrProfileDetails/UserPricing/UserPricing';
import { UserServices } from './GingrProfileDetails/UserServices/UserServices';
import { UserAvailability } from './GingrProfileDetails/UserAvailability/UserAvailability';
import { fetchGingrAction } from '../Gingrs/gingr.action';
import { GeneralGingrState } from '../Gingrs/gingr.reducer';
import UserPhotos from './GingrPhotos/UserPhotos';
import UserVideos from './GingrVideos/UserVideos';
import { DateFormatter, UserStatusUtil } from '../../../../utils/Utils';
import { fetchPendingApprovalUsersCount } from '../../Sidebar/sidebar.action';
import { countUserPhotosToBeApproved } from '../usersAssetsToBeApproved.action';
import UserVerification from './GingrVerification/UserVerification';
import {GingrService} from "../Gingrs/GingrService";
import {PaginatedResultModel} from "../../../../shared/paginated-result.model";
import {UserModel} from "../user.model";
import {GingrProfileDetails} from "./gingrProfileDetails";

export enum APPROVAL_STATUS {
  TO_BE_APPROVE = 0,
  APPROVED = 1,
}

export enum NOTIFICATION_SELFIES {
  'PENDING',
  'COMPLETE',
  'REJECTED',
}

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: '#220720',
      color: '#8a7c88',
    },
  },
};
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#a0ff0f',
    color: '#000000',
    width: 10,
    height: 10,
    top: 18,
    right: 10,
    border: `0.5px solid #000000`,
  },
}));

const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
}));

export const GingrDetails: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const params: any = useParams();
  const [inProgress, setInProgress] = React.useState(false);

  const navigateToGingrsList = () => {
    history.push(`/users/gingrs`);
  };

  const assetsToBeApproved = useSelector(
    (state: AppState) => state.data.user.assetsToBeApproved
  );

  const [approvalProcess, setApprovalProcess] = React.useState({
    rejectButton: false,
    approveButton: false,
  });

  const [gingrProfileDetails, setGingrProfileDetails] =
    React.useState<GingrProfileDetails>({
      id: 0,
      slug: '',
      email: '',
      proUser: false,
      displayname: '',
      dateOfBirth: new Date(),
      birthYear: 0,
      gender: 0,
      nationality: '',
      sexuality: 0,
      food: '',
      drinks: '',
      interests: '',
      smoke: '',
      alcohol: '',
      createdAt: new Date(),
      phonePrefix: '',
      phoneNumber: '',
      occupation: '',
      profileStatus: 0,
      description: '',
      bookingNotes: '',
      traveltypes: [],
      pricing: {
        incall: {
          id: 0,
          active: 0,
          amountPph: 0,
          currency: '',
          furtherHourCheck: 0,
          furtherHours: 0,
          locationMyEstablisment: 0,
          locationMyPlace: 0,
          ppH: null,
          symbol: '',
          admissionfee: null,
          interval: [],
        },
        outcall: {
          id: 0,
          active: 1,
          amountPph: 0,
          currency: '',
          furtherHourCheck: 0,
          furtherHours: 0,
          locationMyEstablisment: null,
          locationMyPlace: null,
          ppH: null,
          symbol: '£',
          clientHome: 0,
          clientHotel: 0,
          events: 0,
          interval: [],
        },
      },
      availability: [],
      services: [],
      languages: [],
      location: {
        formatted_address: '',
        longitude: 0,
        latitude: 0,
        country: '',
        city: '',
        isArea: 0,
        have_working_address: 0,
      },
      physicalDetails: {
        height: '',
        weight: '',
        ethnicity: '',
        hairColour: '',
        eyeColour: '',
        breastImplant: 0,
        genitaliaArea: '',
        brest: '',
        stomach: '',
        waist: '',
        cupSize: '',
        bodyType: [],
        tattoo: null,
        piercingFace: null,
        piercingIntimate: null,
      },
      avatar: {
        id: 0,
        media: '',
        thumb: '',
        thumbblur: '',
        type: '',
      },
      verified: 0,
    });

  useEffect(() => {
    (async () => {
      await getUsersList(params.id)    })();
  }, [params]);

  const classes = useStyles();
  const [currentRoute, setCurrentRoute] = React.useState<
    UserDetailsRoutes | string
  >(UserDetailsRoutes.PERSONAL_DETAILS);
  const [profileDetails, setProfileDetails] = React.useState(
    UserDetailsRoutes.PERSONAL_DETAILS
  );

  const handleChange = (event) => {
    setProfileDetails(event.target.value);
    setCurrentRoute(event.target.value);
  };

  const menuDropdownActive = (route) => {
    const list = [
      UserDetailsRoutes.PROFILE_DETAILS,
      UserDetailsRoutes.PERSONAL_DETAILS,
      UserDetailsRoutes.PHYSICAL_DETAILS,
      UserDetailsRoutes.PRICING,
      UserDetailsRoutes.SERVICES,
      UserDetailsRoutes.AVAILABILITY,
    ];
    return list.includes(route);
  };

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const getUsersList = async (userId: string) => {
    const data = await GingrService.getUser(token, userId);
    if (data) {
      setGingrProfileDetails(data);
    }
  };

  const getApprovalStatus = async (): Promise<void> => {
    if (gingrProfileDetails && gingrProfileDetails.id !== 0) {
      const response: boolean =
        await AdminUserService.getApproveStatus(
          gingrProfileDetails.id, token
        );
      setApprovalProcess({
        ...approvalProcess,
        approveButton: response,
        rejectButton: gingrProfileDetails.profileStatus === 1,
      });
    }
  };

  const updateVideosToBeApproved = () => {
    dispatch(countUserPhotosToBeApproved(params.id, 'VIDEOS'));
  };

  React.useEffect(() => {
    (async () => {
      await getApprovalStatus();
    })();
  }, [gingrProfileDetails]);

  const handleApproveUser = async () => {
    if (inProgress) {
      return;
    }
    setInProgress(true);

    if (approvalProcess.approveButton) {
      const approveResponse = await AdminUserService.approveUser(
        gingrProfileDetails.id,
        token
      );
      if (approveResponse) {
        setGingrProfileDetails({
          ...gingrProfileDetails,
          profileStatus: 2,
        });
        dispatch(fetchPendingApprovalUsersCount());
      }
    }

    setInProgress(false);
  };

  const handleRefuseUser = async () => {
    if (inProgress || !approvalProcess.rejectButton) {
      return;
    }
    setInProgress(true);

    const refuseResponse = await AdminUserService.refuseUser(
      gingrProfileDetails.id,
      token
    );
    if (refuseResponse) {
      setGingrProfileDetails({
        ...gingrProfileDetails,
        profileStatus: 0,
      });
      dispatch(fetchPendingApprovalUsersCount());
    }

    setInProgress(false);
  };

  React.useEffect(() => {
    const fullUrl = window.location.href;
    const currentSubPath = fullUrl.substring(fullUrl.lastIndexOf('/') + 1);
    setCurrentRoute(currentSubPath);
    dispatch(countUserPhotosToBeApproved(params.id, 'PHOTOS'));
    dispatch(countUserPhotosToBeApproved(params.id, 'VIDEOS'));
    dispatch(countUserPhotosToBeApproved(params.id, 'SELFIES'));
  }, []);

  const handleActiveRoute = (route: UserDetailsRoutes): boolean => {
    return route === currentRoute;
  };

  return (
    <div className="userDetailsContainer">
      <div className="mainInfo">
        <div className="details" style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className="avatarContainer">
                <StyledBadge variant="dot">
                  <Avatar
                    src={gingrProfileDetails.avatarThumb}
                    className={classes.sizeAvatar}
                  />
                </StyledBadge>
                <div className="nameAndPayment">
                  <p>{gingrProfileDetails.displayName}</p>
                  <ChartButton
                    text={gingrProfileDetails.proUser ? 'Premium' : 'Free'}
                    isSelected={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={1.5} className="gridSpace">
              <p>{gingrProfileDetails.email}</p>
              <span>Email</span>
            </Grid>
            <Grid item xs="auto" className="gridSpace">
              <p>{gingrProfileDetails.mobile}</p>
              <span>Phone</span>
            </Grid>
            <Grid item xs={1.5} className="gridSpace">
              <p>{DateFormatter(gingrProfileDetails.createdAt as Date)}</p>
              <span>Registered</span>
            </Grid>
            <Grid item xs={1} className="gridSpace">
              <p>{gingrProfileDetails.nationality}</p>
              <span>Nationality</span>
            </Grid>
            <Grid item xs="auto" className="gridSpace">
              <SimpleButton
                text={UserStatusUtil[gingrProfileDetails.profileStatus].name}
                backgroundColor={
                  UserStatusUtil[gingrProfileDetails.profileStatus].buttonColor
                }
                fullWidth={true}
                disabled={true}
              />
              <span style={{ display: 'block', marginTop: 12 }}>
                Profile Status
              </span>
            </Grid>
            <Grid
              item
              xs="auto"
              justifyContent="center"
              alignItems="center"
              className="gridSpace"
            >
              <SimpleButton
                text={
                  gingrProfileDetails.verified
                    ? 'Verified'
                    : 'Not Verified'
                }
                backgroundColor={
                  gingrProfileDetails.verified
                    ? 'rgb(46, 125, 50)'
                    : 'rgb(138, 138, 134)'
                }
                fullWidth={true}
                disabled={false}
                noOpacity={true}
              />
              <span style={{ display: 'block', marginTop: 12 }}>
                Verification Status
              </span>
            </Grid>
            <Grid item xs="auto" alignItems="center" className="gridSpace">
              <SimpleButton
                text={'Refuse Profile'}
                backgroundColor={'#ff3a3a'}
                fullWidth={true}
                onClick={handleRefuseUser}
                disabled={!approvalProcess.rejectButton}
              />

              <SimpleButton
                text={'Approve Profile'}
                fullWidth={true}
                backgroundColor={
                  !approvalProcess.approveButton ? '#8a8a86' : '#2e7d32'
                }
                marginTop={8}
                onClick={handleApproveUser}
                disabled={!approvalProcess.approveButton}
              />
            </Grid>
            <Grid item xs={1} className="gridSpace">
              <div className="edit">
                <OptionsIcon className="editIcons" />
                <EditIcon className="editIcons" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content">
        <div className="sidebar">
          <ul className="sidebar-menu" style={{ width: 220 }}>
            <li>
              <span
                className={`sidebar-menu-item ${
                  menuDropdownActive(currentRoute) ? 'active' : ''
                }`}
              >
                <Select
                  value={profileDetails}
                  displayEmpty
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  disableUnderline
                  placeholder="Profile Details"
                  defaultValue="Profile Details"
                  className={`${
                    menuDropdownActive(currentRoute) ? 'active-select' : ''
                  }`}
                >
                  <ListSubheader
                    value={UserDetailsRoutes.PROFILE_DETAILS}
                    onClick={() =>
                      history.push(
                        `${url}/${UserDetailsRoutes.PERSONAL_DETAILS}`
                      )
                    }
                  >
                    Profile Details
                  </ListSubheader>
                  <MenuItem
                    value={UserDetailsRoutes.PERSONAL_DETAILS}
                    onClick={() =>
                      history.push(
                        `${url}/${UserDetailsRoutes.PERSONAL_DETAILS}`
                      )
                    }
                  >
                    Personal Details
                  </MenuItem>
                  <MenuItem
                    value={UserDetailsRoutes.PHYSICAL_DETAILS}
                    onClick={() =>
                      history.push(
                        `${url}/${UserDetailsRoutes.PHYSICAL_DETAILS}`
                      )
                    }
                  >
                    Physical Details
                  </MenuItem>
                  <MenuItem
                    value={UserDetailsRoutes.PRICING}
                    onClick={() =>
                      history.push(`${url}/${UserDetailsRoutes.PRICING}`)
                    }
                  >
                    Pricing
                  </MenuItem>
                  <MenuItem
                    value={UserDetailsRoutes.SERVICES}
                    onClick={() => history.push(UserDetailsRoutes.SERVICES)}
                  >
                    Services
                  </MenuItem>
                  <MenuItem
                    value={UserDetailsRoutes.AVAILABILITY}
                    onClick={() => history.push(UserDetailsRoutes.AVAILABILITY)}
                  >
                    Availability
                  </MenuItem>
                </Select>
              </span>
            </li>
            <li>
              <LinkItem
                url={url}
                notificationNumber={assetsToBeApproved.photosToReview}
                sub={UserDetailsRoutes.PHOTOS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.PHOTOS)}
                isActive={handleActiveRoute(UserDetailsRoutes.PHOTOS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.VIDEOS}
                notificationNumber={assetsToBeApproved.videosToReview}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.VIDEOS)}
                isActive={handleActiveRoute(UserDetailsRoutes.VIDEOS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.BOOKINGS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.BOOKINGS)}
                isActive={handleActiveRoute(UserDetailsRoutes.BOOKINGS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.SUBSCRIPTIONS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.SUBSCRIPTIONS)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.SUBSCRIPTIONS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.REFERRALS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.REFERRALS)}
                isActive={handleActiveRoute(UserDetailsRoutes.REFERRALS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.VERIFICATION}
                notificationNumber={assetsToBeApproved.selfiesToRevew}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.VERIFICATION)}
                isActive={handleActiveRoute(UserDetailsRoutes.VERIFICATION)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.REVIEWS}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.REVIEWS)}
                isActive={handleActiveRoute(UserDetailsRoutes.REVIEWS)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.ACTIVITIES}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.ACTIVITIES)}
                isActive={handleActiveRoute(UserDetailsRoutes.ACTIVITIES)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.SECURITY}
                setRoute={() => setCurrentRoute(UserDetailsRoutes.SECURITY)}
                isActive={handleActiveRoute(UserDetailsRoutes.SECURITY)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.TIER_ALLOCATION}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.TIER_ALLOCATION)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.TIER_ALLOCATION)}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.COMPLIANCE_STATUS}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.COMPLIANCE_STATUS)
                }
                isActive={handleActiveRoute(
                  UserDetailsRoutes.COMPLIANCE_STATUS
                )}
              />
            </li>
            <li>
              <LinkItem
                url={url}
                sub={UserDetailsRoutes.EXPORT_FUNCTION}
                setRoute={() =>
                  setCurrentRoute(UserDetailsRoutes.EXPORT_FUNCTION)
                }
                isActive={handleActiveRoute(UserDetailsRoutes.EXPORT_FUNCTION)}
              />
            </li>
          </ul>
        </div>
        <div style={{ width: '100%' }}>
          <Switch>
            <Route path={`${url}/${UserDetailsRoutes.PHOTOS}`} exact>
              <UserPhotos
                photosToReview={assetsToBeApproved.photosToReview}
                callbackStatus={getApprovalStatus}
                userId={params.id}
                profileStatus={gingrProfileDetails.profileStatus}
              />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.VIDEOS}`}>
              <UserVideos
                videosToReview={assetsToBeApproved.videosToReview}
                callbackStatus={getApprovalStatus}
                updateVideosToBeApproved={updateVideosToBeApproved}
                userId={params.id}
                profileStatus={gingrProfileDetails.profileStatus}
              />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.BOOKINGS}`} />
            <Route path={`${url}/${UserDetailsRoutes.SUBSCRIPTIONS}`} />
            <Route path={`${url}/${UserDetailsRoutes.REFERRALS}`} />
            <Route path={`${url}/${UserDetailsRoutes.VERIFICATION}`}>
              <UserVerification
                selfiesToRevew={assetsToBeApproved.selfiesToRevew}
                callbackStatus={getApprovalStatus}
                userId={params.id}
                profileStatus={gingrProfileDetails.profileStatus}
              />
            </Route>
            <Route path={`${url}/${UserDetailsRoutes.REVIEWS}`} />
            <Route path={`${url}/${UserDetailsRoutes.ACTIVITIES}`} />
            <Route path={`${url}/${UserDetailsRoutes.SECURITY}`} />
            <Route path={`${url}/${UserDetailsRoutes.TIER_ALLOCATION}`} />
            <Route path={`${url}/${UserDetailsRoutes.COMPLIANCE_STATUS}`} />
            <Route path={`${url}/${UserDetailsRoutes.EXPORT_FUNCTION}`} />
            <Route path={`${url}`}>
              <Route path={`${url}/${UserDetailsRoutes.PERSONAL_DETAILS}`}>
                <UserPersonalDetails data={gingrProfileDetails} />
              </Route>
              <Route
                path={`${url}/${UserDetailsRoutes.PHYSICAL_DETAILS}`}
                exact
              >
                <UserPhysicalDetails data={gingrProfileDetails} />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.PRICING}`} exact>
                <UserPricing data={gingrProfileDetails} />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.SERVICES}`} exact>
                <UserServices data={gingrProfileDetails.services} />
              </Route>
              <Route path={`${url}/${UserDetailsRoutes.AVAILABILITY}`} exact>
                <UserAvailability data={gingrProfileDetails.availability} />
              </Route>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
