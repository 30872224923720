import React from 'react';
import './UserFacilities.scss';
import { OneColumnTable } from '../../GingrDetails/OneColumnTable/OneColumnTable';
import { useTranslation } from 'react-i18next';
import { Service } from '../establishmentProfileDetails.reducer';

type UserFacilitiesProps = { data: Service[] };

export const UserFacilities = ({ data }: UserFacilitiesProps) => {
  const [t] = useTranslation('translation');
  const facilities: string[] = [];
  data.map((value) => {
    if (value.service_name) {
      const tempValue = t(`SERVICES.${value.service_name}.NAME`);
      facilities.push(tempValue);
    }
  });
  return (
    <div className="userFacilitiesContainer">
      <div className="left">
        <div className="secondTableContainer">
          <h2>Facilities</h2>
          <OneColumnTable head="" data={facilities} />
        </div>
      </div>
    </div>
  );
};
