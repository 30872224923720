import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';
import { GeneralAgencyProfileDetailsState } from './agencyProfileDetails.reducer';

export interface AgencyImagesToBeApproved {
  id: number;
  user_id: number;
  imageType: string;
  approved: number;
  avatar: number;
  feature: number;
  cover: number;
  active: number;
  censored: number;
  video: number;
  censored_from?: any;
  path?: any;
  checksum?: any;
  disabled: number;
  priority?: any;
  banner: number;
  media: string;
  thumb: string;
  thumbblur: string;
}

const ProductServicePaths = {
  AGENCY_PROFILE_DETAILS: '/admin/users/agency/',
  AGENCY_IMAGES: (id: string) => `/admin/medias/users/${id}/image`,
};

export class AgencyProfileDetailsService {
  static async getAgencyProfileDetails(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<GeneralAgencyProfileDetailsState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.AGENCY_PROFILE_DETAILS + id,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
  static async getAgencyImages(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<Array<AgencyImagesToBeApproved>> {
    const { result } = await GingrClient.get(
      ProductServicePaths.AGENCY_IMAGES(id),
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
}
