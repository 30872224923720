import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { AgencyProfileDetailsService } from './AgencyProfileDetailsService';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import {
  Availability,
  Avatar,
  GeneralAgencyProfileDetailsState,
  Landline,
  Location,
  Mobile,
  Service,
} from './agencyProfileDetails.reducer';

export type AgencyProfileDetailsActionType = 'AGENCY_PROFILE_DETAILS';

export type AgencyProfileDetailsAction =
  | 'ADD_AGENCY_PROFILE_DETAILS'
  | 'CLEAR_AGENCY_PROFILE_DETAILS';

export interface AddAgencyProfileDetailsAction
  extends Action<AgencyProfileDetailsAction> {
  type: 'ADD_AGENCY_PROFILE_DETAILS';
  id: number;
  displayname: string;
  companyName: any;
  firstName: any;
  lastName: any;
  email: string;
  proUser: boolean;
  createdAt: Date;
  slug: string;
  description: string;
  website: any;
  contact: any;
  booking_notes: string;
  currency: string;
  entryFee: any;
  profileStatus: number;
  availability: Availability[];
  services: Service[];
  mobile: Mobile;
  landline: Landline;
  avatar: Avatar;
  location: Location;
}

export interface ClearAgencyProfileDetailsAction
  extends Action<AgencyProfileDetailsAction> {
  type: 'CLEAR_AGENCY_PROFILE_DETAILS';
}

export const addAgencyProfileDetails = (
  id: number,
  displayname: string,
  companyName: any,
  firstName: any,
  lastName: any,
  email: string,
  proUser: boolean,
  profileStatus: number,
  createdAt: Date,
  slug: string,
  description: string,
  website: any,
  contact: any,
  booking_notes: string,
  currency: string,
  entryFee: any,
  availability: Availability[],
  services: Service[],
  mobile: Mobile,
  landline: Landline,
  avatar: Avatar,
  location: Location
): AddAgencyProfileDetailsAction => ({
  type: 'ADD_AGENCY_PROFILE_DETAILS',
  id,
  displayname,
  companyName,
  firstName,
  lastName,
  email,
  proUser,
  profileStatus,
  createdAt,
  slug,
  description,
  website,
  contact,
  booking_notes,
  currency,
  entryFee,
  availability,
  services,
  mobile,
  landline,
  avatar,
  location,
});

export const clearAgentInformation = (): ClearAgencyProfileDetailsAction => ({
  type: 'CLEAR_AGENCY_PROFILE_DETAILS',
});

export const fetchAgencyProfileDetailsAction =
  (id: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('AGENCY_PROFILE_DETAILS'));
    let response: GeneralAgencyProfileDetailsState;
    const credentials = getCredentials(getState());
    const id1 = id;
    try {
      response = await AgencyProfileDetailsService.getAgencyProfileDetails(
        credentials,
        id1
      );
      const {
        id,
        displayname,
        companyName,
        firstName,
        lastName,
        email,
        proUser,
        profileStatus,
        createdAt,
        slug,
        description,
        website,
        contact,
        booking_notes,
        currency,
        entryFee,
        availability,
        services,
        mobile,
        landline,
        avatar,
        location,
      } = response;
      dispatch(
        addAgencyProfileDetails(
          id,
          displayname,
          companyName,
          firstName,
          lastName,
          email,
          proUser,
          profileStatus,
          createdAt,
          slug,
          description,
          website,
          contact,
          booking_notes,
          currency,
          entryFee,
          availability,
          services,
          mobile,
          landline,
          avatar,
          location
        )
      );
    } catch (error) {
      console.log('Error during add users action: ', error);
    }
    dispatch(loadComplete('AGENCY_PROFILE_DETAILS_FETCH'));
  };
